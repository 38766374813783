import { useMediaQuery } from 'react-responsive'

export const useResponsive = () => {
  const IsMobile = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile;
  }

  return {
    IsMobile,
  };
};
