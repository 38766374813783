import React, { useEffect } from "react";
import { useParams } from "react-router";
import { axiosInstance } from "utils/axios";
import {
  Box,
} from "@chakra-ui/react";

const DownloadFilePublic = () => {
  const { filenameIn,  filenameOut} = useParams();
  
  useEffect(() => {
    if (filenameIn) {
      axiosInstance
        .get(`/articles/downloadFilePublic/${filenameIn}/${filenameOut}`, {
          responseType: "blob",
        })
        .then(async (_res) => {
          const url = window.URL.createObjectURL(new Blob([_res.data]));
          const link = document.createElement("a");
          link.href = url;            
          link.setAttribute("download", filenameOut);
          document.body.appendChild(link);
          link.click();
          alert("Download file success!");
        })
        .catch((err) => console.log(err));
    }
  }, [filenameIn]);

  return (
    <Box>
      Thanks for download!
    </Box>
  );
};

export default DownloadFilePublic;
