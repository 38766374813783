import React, { useEffect } from "react";
import {
  Box,
  Button,
  HStack,
  Table,
  Tbody,
  Td,
  Tr,
  VStack,
  Link,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  Icon
} from "@chakra-ui/react";
import RightMenu from "components/UI/RightMenu";
import GlobalVar from "../utils/config.js"
import { useResponsive } from "hooks/useResponsive";
import { HiOutlineMail, HiPhone, HiOutlineChat } from "react-icons/hi";
import { MdCheckCircle, MdSettings } from 'react-icons/md'

const SubmissionGuidelines = () => {
  const {IsMobile} = useResponsive();
  const IsRenderMobile = IsMobile();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <HStack align="flex-start" spacing="4" pb='2'>
      
      <Box w="100%" textAlign="justify">

        <Box fontWeight='bold' pb="5">
          SUBMISSION GUIDELINES
        </Box>

        <Box pb="3">
          <strong>HOTLINE</strong>
          <br/><Icon as={HiOutlineMail}/> Email: <Link color='blue' target='_blank' href="mailto:tapchikhcngtvt@ut.edu.vn">tapchikhcngtvt@ut.edu.vn</Link>
          <br/><Icon as={HiPhone}/> Phone: <Link color='blue' target='_blank' href="tel:028 3803 5341">028 3803 5341</Link>
          <br/><Icon as={HiOutlineChat}/> Zalo: <Link color='blue' target='_blank' href="https://zalo.me/0335814479">0335814479</Link>
        </Box>
        <br/>
        <hr/>
        <Box pb="3">
          <strong>FOR AUTHORS</strong>
          <OrderedList spacing={3}>
            <ListItem>Please download and read carefully the instruction files below to prepare a manuscript:
              <List pt='3' spacing={3}>
                <ListItem><ListIcon as={MdCheckCircle} color='blue.500' />Template File for Preparing a Manuscript in Vietnamese (<Link p='2' borderRadius={'xl'} bgColor={'#3365a4'} color='#fcfcfc' href={GlobalVar.FRONTEND_URI + "/JTST_TemplatePaper_Vietnamese.docx"}>click here</Link>)</ListItem>
                <ListItem><ListIcon as={MdCheckCircle} color='blue.500' />Template File for Preparing a Manuscript in English (<Link p='2' borderRadius={'xl'} bgColor={'#3365a4'} color='#fcfcfc' href={GlobalVar.FRONTEND_URI + "/JTST_TemplatePaper_English.docx"}>click here</Link>)</ListItem>
              </List>
            </ListItem>
            <ListItem>All manuscripts must be submitted via the online system. Guide for online manuscript submission (<Link target='_blank' p='2' borderRadius={'xl'} bgColor={'#3365a4'} color='#fcfcfc' href={GlobalVar.FRONTEND_URI + "/JTST_SubmissionGuidelines_Author.pdf"}>click here</Link>).</ListItem>
          </OrderedList>
        </Box>
        <br/>
        <hr/>
        <Box pb="3">
        <strong>FOR REVIEWERS</strong><br/>
        Transport and Communications Science Journal has the pleasure to invite scientists all over the world to offer the enthusiastic and valuable support in peer-review as well as submission of manuscripts to the Journal.
          <List pt='3' spacing={3}>
            <ListItem> 
            <ListIcon as={MdCheckCircle} color='blue.500' /> Guide for reviewers: <Link target='_blank' p='2' borderRadius={'xl'} bgColor={'#3365a4'} color='#fcfcfc' href={GlobalVar.FRONTEND_URI + "/JTST_SubmissionGuidelines_Reviewer.pdf"}>click here</Link>
            </ListItem>
            <ListItem>
            <ListIcon as={MdCheckCircle} color='blue.500' /> Peer review process: <Link target='_blank' p='2' borderRadius={'xl'} bgColor={'#3365a4'} color='#fcfcfc' href={GlobalVar.FRONTEND_URI + "/JTST_SubmissionGuidelines_Reviewer.pdf"}>click here</Link>
            </ListItem>
          </List>
        </Box>
        <br/>
        <hr/>
        <Box pb="3">
          <strong>FOR READERS</strong>
          <List spacing={3}>
            <ListItem><ListIcon as={MdCheckCircle} color='blue.500' />All issues and articles are available at &ldquo;Volumes and issues&rdquo; menu tab.</ListItem>
            <ListItem><ListIcon as={MdCheckCircle} color='blue.500' />Please send all comments and discussions to the following email: <Link color='blue' target='_blank' href="mailto:tapchikhcngtvt@ut.edu.vn">tapchikhcngtvt@ut.edu.vn</Link> and via the corresponding author email on the first page of the article.</ListItem>
          </List>
        </Box>
      </Box>

      {!IsRenderMobile && 
        <RightMenu/>}
    </HStack>
    {IsRenderMobile && 
      <RightMenu/>}
    </>
  
  );
};

export default SubmissionGuidelines;
