import { VStack, Box, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import React from "react";
import { useTranslate } from "hooks/useTranslate";

const RightMenu = () => {
  const { t } = useTranslate();

  return (
    <VStack align="stretch" 
      // w="30%" 
      minW="60" 
      // maxW="96"
      >
      <VStack
        spacing="4"
        align="stretch"
        p="4"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="md"
        color="blue.600"
      >
        <Box color="black" fontSize="xl" fontWeight="semibold">
          {t("for_author")}
        </Box>
        <hr />

        <Link
          to={{
            pathname: "/submission-guidelines/",
          }}
          // target="_blank"
        >
          <Box cursor="pointer">{t("submit_guidelines")}</Box>
        </Link>
        <Link
          to={{
            pathname: "/contact-the-journal/",
          }}
          // target="_blank"
        >
          <Box cursor="pointer">{t("contact_the_journal")}</Box>
        </Link>

        <Link to="/articles/new">
          <Button colorScheme="blue" w="100%" bgColor={'#3365a4'}>
            {t("submit_manuscript")}
          </Button>
        </Link>
      </VStack>

      <VStack
        spacing="4"
        align="stretch"
        p="4"
        border="2px solid"
        borderColor="gray.200"
        borderRadius="md"
        color="blue.600"
      >
        <Box color="black" fontSize="xl" fontWeight="semibold">
          {t("explore")}
        </Box>
        <hr />

        <Link to="/volumes-and-issues">
          <Box>{t("volume_and_issues")}</Box>
        </Link>
      </VStack>

      <Box 
        p="2"
        border="2px solid"
        borderColor="gray.200"
        borderRadius="md"
        color="blue.600">
        <iframe 
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ftapchikhcngtvt&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1590959634400007" 
          width="100%" 
          height="400" 
          allowfullscreen="true" 
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">

        </iframe>
      </Box>
    </VStack>
  );
};

export default RightMenu;
