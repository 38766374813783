import React, { useEffect } from "react";
import {
  Box,
  HStack,
  Grid
} from "@chakra-ui/react";
import RightMenu from "components/UI/RightMenu";
import { useResponsive } from "hooks/useResponsive";

const EditorialBoard = () => {
  const {IsMobile} = useResponsive();
  const IsRenderMobile = IsMobile();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
  <>
  <HStack align="flex-start" spacing="4" pb='2'>
    <Box w="100%">
      {/* <Box fontWeight='bold' pb="7">
        EDITORS
      </Box> */}
      <Box pb="5">
        <p><strong>Editor-in-chief</strong></p>
        <p>Assoc. Prof. Nguyen Tien Thuy</p>
        <p>Ho Chi Minh City University of Transport</p>
      </Box>

      <hr />
      <Box pb="5">
        <p><strong>Managing Editor</strong></p>
        <Grid templateColumns="repeat(2, 1fr)" gap="4" placeItems="right">
          <Box>
            <p>Dr. Nguyen Quang Sang</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
        </Grid>
      </Box>

      <hr />
      <Box pb="5">
        <p><strong>Editorial Board</strong></p>
        <Grid templateColumns="repeat(2, 1fr)" gap="4" placeItems="right">
          <Box>
            <p>Assoc. Prof. Nguyen Xuan Phuong</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>

          <Box>
            <p>Prof. Le Anh Tuan</p>
            <p>Hanoi University of Science and Technology</p>
          </Box>
          <Box>
            <p>Assoc. Prof. Le Hoang An</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Assoc. Prof. Pham Thi Anh</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Assoc. Prof. Dong Van Huong</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Assoc. Prof. Dang Xuan Kien</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Assoc. Prof. Nguyen Van Long</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Assoc. Prof. Vu Van Nghi</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Assoc. Prof. Tran Quang Phu</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Assoc. Prof. Vo Cong Phuong</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Assoc. Prof. Tran Ngoc Thanh</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Assoc. Prof. Hoang Anh Tuan</p>
            <p>Dong A University</p>
          </Box>
          <Box>
            <p>Assoc. Prof. Nguyen Anh Tuan</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Dr. Le Ba Anh</p>
            <p>University of Transport and Communications</p>
          </Box>
          <Box>
            <p>Dr. Le Van Quoc Anh</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Dr. Nguyen Tuan Anh</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Dr. Dang Nhan Cach</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Dr. Do Hung Chien</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Dr. Pham Tien Cuong</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Dr. Dinh Gia Huy</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>

          <Box>
            <p>Dr. Mai Luu</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Dr. Nguyen Thanh Sa</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Dr. Tran Thien Thanh</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Dr. Le Van Vang</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
        </Grid>
      </Box>

      <hr />
      <Box pb="5">
        <p><strong>Editor Assistant</strong></p>
        <Grid templateColumns="repeat(2, 1fr)" gap="4" placeItems="right">
          <Box>
            <p>Dr. Doan Van Dong</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>BA. Nguyen Thi Ngoc Mai</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
        </Grid>
      </Box>
    </Box>
    {!IsRenderMobile && 
        <RightMenu/>}
  </HStack>

  {IsRenderMobile && 
    <RightMenu/>}
  </>
)};

export default EditorialBoard;
