import React, { useEffect } from "react";
import {
  Box,
  Button,
  HStack,
  Table,
  Tbody,
  Td,
  Tr,
  VStack,
} from "@chakra-ui/react";
import RightMenu from "components/UI/RightMenu";

const JournalUpdates = () => {
  const IsMobile = true;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <HStack align="flex-start" spacing="4">
      
      <Box w="100%">
              <div>
                  <p><strong>Editor-in-Chief</strong></p>
  
                  <p>Ramjee Prasad<br/>
                  ramjee@btech.au.dk<br/>
                  Professor, Future Technologies for Business Ecosystem Innovation (FT4BI),<br/>
                  President, CTIF Global Capsule (CGC)<br/>
                  Department of Business Development and Technology,<br/>
                  Aarhus University, Denmark<br/>
                  </p>
  
                  <p><strong>Associate Editors</strong><br/>
                  Pardeep Kumar,QUEST University Nawabshah, Pakistan<br/>
                  Paulo P. Monteiro,University of Aveiro and Instituto de Telecomunicações, Portugal<br/>
                  Kashif Nisar, University Malaysia Sabah, Malaysia<br/>
                  Pablo Otero,University of Malaga, Malaga, Spain<br/>
                  Faisal K. Shaikh,Mehran University of Engineering &amp; Technology, Jamshoro, Pakistan</p>
  
  
  
                  <p><strong>Editorial Board</strong></p>
  
                  <p>Matti Latva-aho<br/>
                  Director of 6G Flagship (www.6gflagship.com)<br/>
                  Centre for Wireless Communications<br/>
                  University of Oulu, Finland</p>
  
                  <p>Sumei Sun<br/>
                  Singapore Institute of Technology/Institute for Infocom Research<br/>
                  Singapore</p>
  
                  <p>Ma Maode<br/>
                  School of Electrical and Electronic Engineering at Nanyang Technological University<br/>
                  Singapore</p>
  
                  <p>Kwang-Cheng Chen<br/>
                  Professor, Department of Electrical Engineerng<br/>
                  University of South Florida<br/>
                  USA</p>
  
                  <p>Dominique Noguet<br/>
                  CEA-Leti,<br/>
                  Grenoble, France</p>
  
  
              </div>
          </Box>
          {!IsMobile && 
        <RightMenu/>}
    </HStack>
  
  );
};

export default JournalUpdates;
