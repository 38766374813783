import {
  Box,
  Button,
  Input,
  TableCaption,
  TableContainer,
  Tfoot,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Tag,
  Select,
  HStack,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Grid,
  GridItem,
  RadioGroup,
  Radio,
  Textarea,
  List,
  ListItem,
  ListIcon,
  Portal,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Collapse,
  Link as UILink,
  Icon,
  Tooltip, 
  TagLeftIcon,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";
import { MdCheckCircle, MdSettings, MdTimeline, MdTimer } from 'react-icons/md'
import { useDisclosure } from "@chakra-ui/hooks";
import SendEmailModal from "components/UI/SendEmailModal";
import { GlobalContext } from "context/GlobalContext";
import withRole from "hocs/withRole";
import { EditIcon, ArrowUpIcon, ArrowBackIcon, ViewIcon, EmailIcon } from '@chakra-ui/icons'
import {
  ARTICLE_STATUSES,
  EDITOR_IN_CHIEF_STATUSES,
  EDITOR_STATUSES,
  MODAL_TITLES,
  REVIEW_STATUSES,
  ROLES,
  PUBLISHER_STATUSES,
  CONSTANTS_DATA,
} from "keys";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { axiosInstance } from "utils/axios";
import { timestampToDate } from "utils/time";
import GlobalVar from 'utils/config'

const Reviews = () => {
  const { role } = useParams();
  const history = useHistory();
  const { user } = useContext(GlobalContext);

  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [emails, setEmails] = useState([]);
  const [currentStatuses, setCurrentStatuses] = useState([]);
  const [statusSelected, setStatusSelected] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [declineReason, setDeclineReason] = useState("");

  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [openReviewResultModal, setOpenReviewResultModal] = useState(false);
  const [reviewSelected, setReviewSelected] = useState();
  const [viewReviewSelected, setViewReviewSelected] = useState(false);
  const [fileReview, setFileReview] = useState();

  // flag refresh
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    setLoading(true);
    if (user) {
      if (!role || user.role % role !== 0) return history.push("/dashboard");
      setLoading(false);
    }
  }, [user, role]);

  useEffect(() => {
    if (role && !loading) {
      // console.log(role);
      switch (+role) {
        case ROLES.AUTHOR:
          setCurrentStatuses(ARTICLE_STATUSES);
          break;

        case ROLES.REVIEWER:
          setCurrentStatuses(REVIEW_STATUSES);
          break;

        case ROLES.EDITOR_IN_CHIEF:
          setCurrentStatuses(EDITOR_IN_CHIEF_STATUSES);
          break;

        case ROLES.EDITOR:
          setCurrentStatuses(EDITOR_STATUSES);
          break;

        case ROLES.REVIEWER:
          setCurrentStatuses(REVIEW_STATUSES);
          break;

        case ROLES.PUBLISHER:
          setCurrentStatuses(PUBLISHER_STATUSES);
          break;

        default:
          setCurrentStatuses([]);
          break;
      }

      axiosInstance
        .get(`/articles/roles/${role}`)
        .then((res) => {
          setArticles(res.data);
        })
        .catch((err) => console.log(err));

      axiosInstance
        .get(`/articles/reviews/${role}`)
        .then((res) => {
          setReviews(res.data);
        })
        .catch((err) => console.log(err));

      // get all email related Current User
      axiosInstance
        .get(`/articles/emails/${user._id}`)
        .then((res) => {
          setEmails(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [role, loading, refresh]);

  const hanldeDownload = (article) => {
    axiosInstance
      .get(`/articles/${article._id}/download`, {
        responseType: "blob",
      })
      .then(async (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let filenameDownload = article.file.filename;
        const fileType = filenameDownload.substr(filenameDownload.lastIndexOf("."));
        if (article.manuscriptId)
          filenameDownload = article.manuscriptId + fileType
        link.href = url;
        link.setAttribute("download", filenameDownload);
        document.body.appendChild(link);
        link.click();
      });
  };

  const colorOfStatus = (status) => {
    let color;
    switch (status) {
      case ARTICLE_STATUSES.WITH_EDITOR:
      case EDITOR_IN_CHIEF_STATUSES.NEW_SUBMISSIONS:
      case EDITOR_STATUSES.NEW_INVITATION:
      case REVIEW_STATUSES.NEW_INVITATION:
        color = "gray";
        break;

      case ARTICLE_STATUSES.REJECTED:
      case REVIEW_STATUSES.REJECTED:
      case EDITOR_IN_CHIEF_STATUSES.REJECTED_DECISION:
      case EDITOR_IN_CHIEF_STATUSES.RETURN_AUTHOR:
        color = "red";
        break;

      case ARTICLE_STATUSES.WITH_REVIEWER:
      case EDITOR_IN_CHIEF_STATUSES.ASSIGNED_EDITOR:
        color = "blue";
        break;

      case ARTICLE_STATUSES.NEED_REVISION:
      case ARTICLE_STATUSES.WAIT_FINAL_CHECK:
      case ARTICLE_STATUSES.WAIT_APPROVE:
      case REVIEW_STATUSES.INCOMPLETE:
      case EDITOR_STATUSES.INCOMPLETE_ASSIGNMENT:
      case EDITOR_STATUSES.WAIT_FOR_AUTHOR_REVISE:
      case EDITOR_STATUSES.AUTHOR_SENT_BACK_REVISED:
      case EDITOR_IN_CHIEF_STATUSES.RESULT_EDITOR:
      case PUBLISHER_STATUSES.WAIT_FINAL_CHECK:
        color = "orange";
        break;

      case ARTICLE_STATUSES.ACCEPTED:
        color = "green";
        break;

      case ARTICLE_STATUSES.PUBLISHED:
      case EDITOR_IN_CHIEF_STATUSES.PUBLISHED:
        color = "teal";
        break;

      default:
        color = "green";
        break;
    }
    return color;
  };

  const checkStatus = (status) => {
    return (
      <Tag
        size="sm"
        variant="solid"
        colorScheme={colorOfStatus(status)}
        textTransform="uppercase"
        textAlign="center"
        py="2"
      >
        {status}
      </Tag>
    );
  };

  const getCorrespondingAuthor = (article) => {
    for (let i = 0; i < article.info?.authors.length; i++) {
      if (article.info?.authors[i].id)
        return article.info?.authors[i];
    }
    return undefined;
  }

  const getFileNameOut = (fileData) => {
    let ext = fileData?.originalname.substring(fileData?.originalname.lastIndexOf(".")+1);
    let yyyymmdd = new Date().toISOString().slice(0,10).replace(/-/g,"");
    let fileNameOut = `JTST_${yyyymmdd}_${fileData?.filename}.${ext}`;

    return fileNameOut;
  }
  
  const getReviewContent = async (article, isToAuthor = true) => {
    let reviewData = undefined;
    await axiosInstance.get(`/articles/${article._id}/reviews`).then((res) => {
      const _res = res.data.filter((r) => r.isCompleted);
      setReviewResults(_res);
      reviewData = _res;
    });

    let reviewContent = '';
    reviewData?.length &&
    reviewData.map((review, idx) => {
        let reviewerInfo = '';
        if (!isToAuthor)
          reviewerInfo = ` (${review.reviewer.title}. ${review.reviewer.lastname} ${review.reviewer.firstname} - ${review.reviewer.email})`;

        reviewContent +=
        `REVIEWER ${idx + 1}${reviewerInfo}:
- Relevance and timeliness: ${review.scores?.reievance}
- Technical content and scientific rigour: ${review.scores?.technicalContent}
- Novelty and originality: ${review.scores?.novelty}
- Quality of presentation: ${review.scores?.quality}
- Comment to author: ${review.strongAspects}`;
        
if (!isToAuthor)
          reviewContent += `
- Comment confidential to Editor: ${review.weakAspects}`;

reviewContent += `
- Recommendation: ${getDescRecommendation(review)}
`
if (review?.fileReview)
reviewContent += `- Attach file: ${GlobalVar.FRONTEND_URI}/download-file/${review._id}/${getFileNameOut(review?.fileReview)}
`;

});

    if (reviewContent.length > 0 && isToAuthor)
      reviewContent = `The comments of the reviewers who evaluated your manuscript are included below:
${reviewContent}`;

    return reviewContent;
  }

  const handleApproveByAuthor = (article) => {
    if (!window.confirm("Are you OK?"))
      return;

    axiosInstance
      .put(`/articles/${article._id}/status`, {
        status: ARTICLE_STATUSES.WITH_EDITOR,
        data: article,
      })
      .then((res) => {
        const idx = articles.findIndex((a) => a._id === article._id);
        if (idx !== -1) {
          //send mail when success


          setArticles((pre) => [
            ...pre.slice(0, idx),
            res.data,
            ...pre.slice(idx + 1, pre.length),
          ]);
        }
      })
      .catch((err) => console.log(err));
  };

  // const [isAcceptPublish, setIsAcceptPublish] = useState("1");
  // const [comments, setComments] = useState("");
  const [reviewScores, setReviewScores] = useState({
    reievance: 1,
    technicalContent: 1,
    novelty: 1,
    quality: 1,
  });
  const [strongAspects, setStrongAspects] = useState("");
  const [weakAspects, setWeakAspects] = useState("");
  const [recommendedChanges, setRecommendedChanges] = useState("N/A");
  const [recommendation, setRecommendation] = useState("");

  useEffect(() => {
    if (reviewSelected) {
      // setIsAcceptPublish(reviewSelected.isAcceptPublish ? "1" : "0");
      // setComments(reviewSelected.comments);
      setReviewScores(reviewSelected.scores);
      setStrongAspects(reviewSelected.strongAspects);
      setWeakAspects(reviewSelected.weakAspects);
      setRecommendedChanges(reviewSelected.recommendedChanges);
      setRecommendation(reviewSelected.recommendation);
    }
  }, [reviewSelected]);


  const handleUpdateFileReview = (review, fileReview) => {
    const formData = new FormData();
    formData.append("fileReview", fileReview);
    // console.log(fileReview);
    // console.log(review);
    
    axiosInstance
      .put(`/articles/${review.article}/reviews/${review._id}/updateFileReview`, formData)
      .then((res) => {
        alert("Upload & Save File review SUCCESS!");
        viewResultReview(review.article);
      })
      .catch((err) => console.log(err));
  };

  const hanldeReviewArticle = (review, isApprove = false) => {
    const data = {
      scores: reviewScores,
      strongAspects,
      weakAspects,
      recommendedChanges,
      recommendation
    };
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    formData.append("fileReview", fileReview);
    
    axiosInstance
      .put(`/articles/${review.article._id}/reviews/${review._id}`, formData)
      .then((res) => {
        const idx = articles.findIndex((a) => a._id === review._id);
        if (idx !== -1) {
          setArticles((pre) => [
            ...pre.slice(0, idx),
            res.data,
            ...pre.slice(idx + 1, pre.length),
          ]);
        }
        alert("Save review success");
        
        //close Modal
        setOpenReviewModal(false);
        setViewReviewSelected(false);
      })
      .catch((err) => console.log(err));
  };

  const UpdateReviewActions = {
    AcceptInvitation: "accept_invitation",
    DeclineInvitation: "decline_invitation",
    ApproveReview: "approve_review",
  };

  const handleUpdateReview = (review, type, uncheck = false) => {
    let reason_input_value = '';

    if (type === UpdateReviewActions.DeclineInvitation) {
      reason_input_value = document.getElementById("reason_input_" + review._id).value;
      if (declineReason === '1')
        reason_input_value = 'I am not available this time';
      else if (declineReason === '2')
        reason_input_value = 'This topic is out of my area';

      if (!(declineReason in ['0', '1', '2'])
        || (declineReason === '0' && reason_input_value.length <= 0)) {
        alert('!!! Please choose REASON to decline invitation !!!');
        return;
      }
    }

    if (
      uncheck &&
      (!strongAspects?.trim() ||
        !weakAspects?.trim() ||
        !recommendedChanges?.trim() ||
        !recommendation?.trim())
    ) {
      return alert("Please fill all required fields");
    }

    if (window.confirm("Are you OK?")) {
      const formData = new FormData();
      const data = {
        scores: reviewScores,
        strongAspects,
        weakAspects,
        recommendedChanges,
        declineReason: reason_input_value,
        recommendation,
      };

      formData.append("type", type);
      formData.append("data", JSON.stringify(data));
      formData.append("fileReview", fileReview);

      axiosInstance
        .put(`/articles/${review.article._id}/reviews/${review._id}`, formData)
        .then((res) => {
          const idx = articles.findIndex((a) => a._id === review._id);
          if (idx !== -1) {
            setArticles((pre) => [
              ...pre.slice(0, idx),
              res.data,
              ...pre.slice(idx + 1, pre.length),
            ]);
          }
          let msg =
            UpdateReviewActions.DeclineInvitation === type
              ? "Decline success"
              : !uncheck
                ? "Accept review success"
                : "Review success";
          alert(msg);

          //close Modal
          if (UpdateReviewActions.ApproveReview === type)
          {
            setOpenReviewModal(false);
            setViewReviewSelected(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const [reviewResults, setReviewResults] = useState([]);
  let reviewData;

  const viewResultReview = (articleId) => {
    setOpenReviewResultModal(true);

    axiosInstance.get(`/articles/${articleId}/reviews`).then((res) => {
      const _res = res.data.filter((r) => r.isCompleted);
      setReviewResults(_res);
    });
  };

  const getAllReviewData = (item) => {
    let allReview = [];
    let totalReviewer = 0, reviewerRejected = 0, reviewerAccepted = 0, reviewerCompleted = 0;

    reviews.map((review, idx) => {
      if (review.article === item._id) {
        allReview.push(review);
        if (review.status === REVIEW_STATUSES.COMPLETED)
          reviewerCompleted++;
        if (review.status === REVIEW_STATUSES.REJECTED)
          reviewerRejected++;
      }

    });
    totalReviewer = allReview.length;
    reviewerAccepted = totalReviewer - reviewerRejected;

    return {
      allReview,
      totalReviewer,
      reviewerAccepted,
      reviewerRejected,
      reviewerCompleted
    };
  }

  function ViewFileButton(props) {
    const a = props.article;
    var linkFile = `/download-file-public/${a?.file?.filename}/${getFileNameOut(a?.file)}`;
    if (props.isReviewer && a?.fileForReviewer)
      linkFile = `/download-file-public/${a?.fileForReviewer?.filename}/${getFileNameOut(a?.fileForReviewer)}`;

    return (
      <>
        <UILink 
          href= {linkFile} 
          isExternal>
          <Button
            size="xs"
            colorScheme="blue"
          >
            View File
          </Button>
        </UILink>
      </>
    );
  }

  function PopupListMail(props) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [viewDetailEmail, setViewDetailEmail] = useState(false);
    const [currentEmail, setCurrentEmail] = useState();
    const itemArticle = props.article;
    const listEmail = emails.filter((e) => e.article?._id == itemArticle?._id);
    let isHideFromTo = (+role === ROLES.AUTHOR) || (+role === ROLES.REVIEWER);
    // console.log(emails);
    // console.log(listEmail);
    // console.log(isHideFromTo);

    function onViewDetailEmail() {
      setViewDetailEmail(!viewDetailEmail);
    }

    function stripHtml(html)
    {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    }

    function getListToEmail(email)
    {
      let tmp = '';
      if (email?.to)
        tmp += email?.to;
      if (email?.cc)
        tmp += ', ' + email?.cc;
      if (email?.bcc)
        tmp += ', ' + email?.bcc;
      return tmp;
    }

    return (
      <>
        <Button size="xs" 
          hidden={listEmail.length <= 0}
          mt="1"
          colorScheme="blue" 
          onClick={onOpen}>
          <EmailIcon/>
        </Button>

        {listEmail.length > 0 && 
        <Modal size="full" isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader w='95%'>All email about: {itemArticle?.info?.title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box textAlign="justify">
                <hr/>
                <TableContainer hidden={viewDetailEmail}>
                  <Table variant='striped' colorScheme='teal' size='sm'>
                    <Thead>
                      <Tr>
                        <Th></Th>
                        <Th hidden={isHideFromTo}>From</Th>
                        <Th hidden={isHideFromTo}>To</Th>
                        <Th>Email type</Th>
                        <Th>Subject</Th>
                        <Th>Content</Th>
                        <Th>Time</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {listEmail.map((item, idx) => (
                      <Tr>
                        <Td>
                          <Tooltip hasArrow label='View detail' bg='red.600'>
                            <Button colorScheme='blue' size='xs'
                              onClick={()=>{
                                onViewDetailEmail();
                                setCurrentEmail(item);
                              }}>
                              <ViewIcon/>
                            </Button>
                          </Tooltip>
                        </Td>
                        <Td hidden={isHideFromTo}><Box className="one-line-text" maxW={200}>{item?.from.email}</Box></Td>
                        <Td hidden={isHideFromTo}><Box className="one-line-text" maxW={200}>{getListToEmail(item)}</Box></Td>
                        <Td><Box>{item.emailType}</Box></Td>
                        <Td><Box className="one-line-text" maxW={(window.innerWidth - 700)/2}>{item.subject}</Box></Td>
                        <Td><Box className="one-line-text" maxW={(window.innerWidth - 700)/2}>{stripHtml(item.content)}</Box></Td>
                        <Td><Box className="one-line-text">{timestampToDate(item.createdAt)}</Box></Td>
                      </Tr>
                      ))}
                      
                    </Tbody>
                  </Table>
                </TableContainer>
                 
                <Grid 
                  hidden={!viewDetailEmail}
                  templateColumns="6em 1fr" gap="2" textAlign='left' alignItems='center'>
                  <Button onClick={onViewDetailEmail} leftIcon={<ArrowBackIcon />} colorScheme='blue' size='xs'>
                    Back
                  </Button>
                  <Box fontWeight='bold'>
                    <Tag size={'md'} variant='outline' colorScheme='blue'>
                      <TagLeftIcon as={MdTimer} /> 
                      <TagLabel>{timestampToDate(currentEmail?.createdAt)}</TagLabel>
                    </Tag>
                    {' ' + currentEmail?.subject}
                  </Box>

                  <Box hidden={isHideFromTo} fontWeight='bold'>From</Box>
                  <Box hidden={isHideFromTo} >{currentEmail?.from.email}</Box>

                  <Box hidden={isHideFromTo} fontWeight='bold'>To</Box>
                  <Box hidden={isHideFromTo} >{getListToEmail(currentEmail)}</Box>

                  <Box fontWeight='bold'>Content</Box>
                  {/* <Box maxH={400} overflowY={'scroll'}>
                    {currentEmail?.content}
                  </Box> */}
                  <div className="form-email-content" dangerouslySetInnerHTML={{ __html: currentEmail?.content }}>
                  </div>

                  <Box fontWeight='bold'>Attach file</Box>
                  <Box textDecor={currentEmail?.fileAttach ? "underline" : "none"}
                    cursor="pointer" color="blue">
                    <a href={currentEmail?.fileAttach} target='_blank'>
                      {currentEmail?.fileAttach ? currentEmail?.fileAttach : 'Not available'}
                    </a>
                  </Box>
                </Grid>
              </Box>
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        }
      </>
    )
  }


  function PopupText(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()  
    return (
      <>
        <Button size="xs" 
          mt="1"
          colorScheme="blue" 
          onClick={onOpen}>
          {props.label}
        </Button>

        <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{props.header}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box textAlign="justify">
                <hr/>
                {props.content}
              </Box>
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }

  function FileReviewComponent(props) {
    const { isOpen, onToggle } = useDisclosure();
    const [newFileReview, setNewFileReview] = useState();
    const review = props.review;
    return (
      <>
        {review?.fileReview && 
          <ListItem>
            <ListIcon as={MdCheckCircle} color='blue.500' />
            Attach file: 
            <Box textDecor="underline"
                cursor="pointer"
                color="blue">
              <a href={`/download-file/${review._id}/${getFileNameOut(review?.fileReview) }`}
                target='_blank'
              >
                {getFileNameOut(review?.fileReview)}
              </a>

              <Button onClick={onToggle} leftIcon={<EditIcon />} colorScheme='blue' ml="2">
                Edit
              </Button>
            </Box>
            
            <Collapse in={isOpen} animateOpacity>
              <Box
                p='20px'
                color='white'
                mt='4'
                bg='blue.400'
                rounded='md'
                shadow='md'
              >
                <input 
                  // hidden = {viewReviewSelected}
                  type="file" 
                  // accept={'.doc, .docx, .pdf, .txt, .png, .jpg'}
                  onChange={(e) => {
                    setNewFileReview(e.target.files[0]);
                  }}
                />
                <Button onClick={() => {
                      if (!newFileReview) {
                        alert("Please select file to upload ...");
                        return;
                      }

                      if (window.confirm("Upload & Save will replace old file. Are you sure?")) {
                        handleUpdateFileReview(review, newFileReview);
                      }
                    }
                  } 
                  leftIcon={<ArrowUpIcon />} colorScheme='orange' ml="2">
                  Upload & Save
                </Button>
              </Box>
            </Collapse>
            
          </ListItem>
        }
      </>
    )
  }

  const renderActionLinksByRole = (item) => {
    const { status, article } = item;

    switch (+role) {

      case ROLES.AUTHOR:
        switch (status) {
          case ARTICLE_STATUSES.INCOMPLETE:
            return (
              <>
                <Link to={`/articles/${item._id}/edit`}>
                  <Button size="xs" colorScheme="yellow">
                    Edit Submission
                  </Button>
                </Link>
                <Button
                  size="xs"
                  mt="1"
                  colorScheme="red"
                  onClick={() => handleRemoveSubmission(item._id)}
                >
                  Remove Submission
                </Button>
              </>
            );

          case ARTICLE_STATUSES.WAIT_APPROVE:
            return (
              <>
                <Link to={`/articles/${item._id}/edit`}>
                  <Button size="xs" colorScheme="yellow">
                    Edit Submission
                  </Button>
                </Link>
                <Button
                  size="xs"
                  mt="1"
                  colorScheme="red"
                  onClick={() => handleRemoveSubmission(item._id)}
                >
                  Remove Submission
                </Button>
                <Button
                  size="xs"
                  onClick={() => hanldeDownload(item)}
                  colorScheme="blue"
                >
                  View File
                </Button>

                <Button
                  size="xs"
                  onClick={() => handleApproveByAuthor(item)}
                  colorScheme="teal"
                >
                  Approve
                </Button>
              </>
            );

          case ARTICLE_STATUSES.WITH_EDITOR:
            return (
              <>
                <Button
                  size="xs"
                  onClick={() => hanldeDownload(item)}
                  colorScheme="blue"
                >
                  View File
                </Button>

                {/* <Button size="xs" colorScheme="teal">
                  Send email
                </Button> */}
              </>
            );

          case ARTICLE_STATUSES.ACCEPTED:
            return (
              <Button
                size="xs"
                onClick={() => hanldeDownload(item)}
                colorScheme="blue"
              >
                View File
              </Button>
            );

          case ARTICLE_STATUSES.WAIT_FINAL_CHECK:
            return (
              <>
                <Button
                  size="xs"
                  onClick={() => hanldeDownload(item)}
                  colorScheme="blue"
                >
                  View File
                </Button>
                <Button
                  size="xs"
                  mt="1"
                  colorScheme="orange"
                  onClick={() => handleOpenModalReplyPublisherFinalCheck(item)}
                >
                  Reply Final Check
                </Button>
              </>
            );

          case ARTICLE_STATUSES.NEED_REVISION:
            return (
              <>
                <Button
                  size="xs"
                  onClick={() => hanldeDownload(item)}
                  colorScheme="blue"
                >
                  View File
                </Button>
                <Link to={`/articles/${item._id}/edit?type=resubmit`}>
                  <Button size="xs" colorScheme="teal">
                    Resubmit
                  </Button>
                </Link>
              </>
            );
        }

      //  role REVIEWER
      case ROLES.REVIEWER:
        switch (status) {
          case REVIEW_STATUSES.NEW_INVITATION:
            
            return (
              <>
                <PopupText 
                  label='View Abstract'
                  header={'Abstract: ' + article.info.title}
                  content={article.info.summary}
                />

                <Button
                  size="xs"
                  mt="1"
                  colorScheme="blue"
                  onClick={() =>
                    handleUpdateReview(
                      item,
                      UpdateReviewActions.AcceptInvitation
                    )
                  }
                >
                  Accept invitation
                </Button>

                <Popover>
                  <PopoverTrigger>
                    <Button
                      size="xs"
                      mt="1"
                      colorScheme="red"
                    // onClick={setDeclineReason('')}
                    >Decline invitation</Button>
                  </PopoverTrigger>

                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverHeader fontWeight={'bold'}>Reasons to decline the invitation</PopoverHeader>
                    <PopoverCloseButton />
                    <PopoverBody>
                      <Box>
                        <RadioGroup
                          spacing={8}
                          onChange={setDeclineReason}
                          value={declineReason}

                        >
                          <VStack align='left'>
                            <Radio value='1'>I am not available this time</Radio>
                            <Radio value='2'>This topic is out of my area</Radio>
                            <Radio value='0'>Other reasons: </Radio>
                          </VStack>
                        </RadioGroup>
                        <Input
                          id={'reason_input_' + item._id}
                          placeholder="Enter Other reasons ..."
                        />
                        <Box h='3'></Box>
                        <Button
                          colorScheme='blue'
                          onClick={() => handleUpdateReview(
                            item,
                            UpdateReviewActions.DeclineInvitation
                          )}
                        >SEND</Button>
                      </Box>
                    </PopoverBody>
                  </PopoverContent>

                </Popover>
              </>
            );

          case REVIEW_STATUSES.INCOMPLETE:
            return (
              <>
                <ViewFileButton article={article} isReviewer={true} />

                <Button
                  size="xs"
                  mt="1"
                  colorScheme="orange"
                  onClick={() => {
                    setReviewSelected(item);
                    setOpenReviewModal(true);
                  }}
                >
                  Review
                </Button>
                {/* <Button
                  size="xs"
                  mt="1"
                  colorScheme="teal"
                  onClick={async () => {
                    setReviewSelected(item);
                    setOpenReviewModal(true);
                  }}
                >
                  Accept Review
                </Button> */}
              </>
            );

          case REVIEW_STATUSES.COMPLETED:
            return (
              <>
                <ViewFileButton article={article} isReviewer={true} />

                <Button
                  size="xs"
                  mt="1"
                  colorScheme="blue"
                  onClick={() => {
                    setReviewSelected(item);
                    setViewReviewSelected(true);
                    setOpenReviewModal(true);
                  }}
                >
                  View review
                </Button>
              </>
            );
        }

      //  role EDITOR-IN-CHIEF
      case ROLES.EDITOR_IN_CHIEF:
        switch (item.editorInChiefStatus) {
          case EDITOR_IN_CHIEF_STATUSES.NEW_SUBMISSIONS:
            return (
              <>
                <Button
                  size="xs"
                  mt="1"
                  colorScheme="blue"
                  onClick={() => hanldeDownload(item)}
                >
                  View File
                </Button>
                <Button
                  size="xs"
                  mt="1"
                  colorScheme="green"
                  onClick={() => handleOpenModal(item)}
                >
                  Invite editor
                </Button>
                <Button
                  size="xs"
                  mt="1"
                  colorScheme="teal"
                  onClick={() => handleOpenModalSendToPublisher(item)}
                >
                  Accept
                </Button>
                <Button
                  size="xs"
                  mt="1"
                  colorScheme="red"
                  onClick={() => handleRejectDecision(item)}
                >
                  Reject
                </Button>
              </>
            );

          case EDITOR_IN_CHIEF_STATUSES.ASSIGNED_EDITOR:
            return (
              <>
                <Button
                  onClick={() => hanldeDownload(item)}
                  size="xs"
                  mt="1"
                  colorScheme="blue"
                >
                  View File
                </Button>
                <Button
                  size="xs"
                  mt="1"
                  colorScheme={item.editorStatus === EDITOR_STATUSES.REJECTED ? "red" : "green"}
                  onClick={() => handleOpenModal(item)}
                >
                  Invite editor (
                  {
                    item.editorStatus === EDITOR_STATUSES.REJECTED
                      ? 'Rejected invitation'
                      : (item.editorStatus === EDITOR_STATUSES.NEW_INVITATION ? 'New invitation' : 'Accepted invitation')
                  }
                  )
                </Button>
              </>
            );

          case EDITOR_IN_CHIEF_STATUSES.RESULT_EDITOR:
            reviewData = getAllReviewData(item);

            return (
              <>
                <Button
                  onClick={() => hanldeDownload(item)}
                  size="xs"
                  mt="1"
                  colorScheme="blue"
                >
                  View File
                </Button>
                <Button
                  size="xs"
                  mt="1"
                  colorScheme="green"
                  onClick={() => handleOpenModalSendToPublisher(item)}
                >
                  Accept
                </Button>
                <Button
                  size="xs"
                  mt="1"
                  colorScheme="red"
                  onClick={() => handleRejectDecision(item)}
                >
                  Reject
                </Button>
                <Button
                  size="xs"
                  mt="1"
                  colorScheme="yellow"
                  onClick={() => handleOpenModalRevisionToAuthor(item)}
                >
                  Revision
                </Button>
                <Button
                  size="xs"
                  mt="1"
                  colorScheme="red"
                  onClick={() => viewResultReview(item._id)}
                >
                  View Reviewer results ({reviewData.reviewerCompleted}/{reviewData.reviewerAccepted})
                </Button>
              </>
            );

          case EDITOR_IN_CHIEF_STATUSES.REJECTED_DECISION:
            return (
              <Button
                onClick={() => hanldeDownload(item)}
                size="xs"
                mt="1"
                colorScheme="blue"
              >
                View File
              </Button>
            );

          case EDITOR_IN_CHIEF_STATUSES.RETURN_AUTHOR:
            return (
              <>
                <Button
                  onClick={() => hanldeDownload(item)}
                  size="xs"
                  mt="1"
                  colorScheme="blue"
                >
                  View File
                </Button>
                {/* <Button size="xs" mt="1" colorScheme="red">
                  View email
                </Button> */}
              </>
            );

          case EDITOR_IN_CHIEF_STATUSES.SENT_TO_PUBLISHER:
            return (
              <Button
                onClick={() => hanldeDownload(item)}
                size="xs"
                mt="1"
                colorScheme="blue"
              >
                View File
              </Button>
            );

          case EDITOR_IN_CHIEF_STATUSES.PUBLISHED:
            return (
              <Button
                onClick={() => hanldeDownload(item)}
                size="xs"
                mt="1"
                colorScheme="blue"
              >
                View File
              </Button>
            );
        }

      //  role EDITOR
      case ROLES.EDITOR:
        switch (item.editorStatus) {
          case EDITOR_STATUSES.NEW_INVITATION:
            return (
              <>
                <Button
                  onClick={() => hanldeDownload(item)}
                  size="xs"
                  mt="1"
                  colorScheme="blue"
                >
                  View File
                </Button>
                <Button
                  size="xs"
                  mt="1"
                  colorScheme="green"
                  onClick={() => handleEditorAccept(item._id)}
                >
                  Accept invitation
                </Button>

                <Popover>
                  <PopoverTrigger>
                    <Button
                      size="xs"
                      mt="1"
                      colorScheme="red"
                    // onClick={setDeclineReason('')}
                    >Decline invitation</Button>
                  </PopoverTrigger>

                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverHeader fontWeight={'bold'}>Reasons to decline the invitation</PopoverHeader>
                    <PopoverCloseButton />
                    <PopoverBody>
                      <Box>
                        <RadioGroup
                          spacing={8}
                          onChange={setDeclineReason}
                          value={declineReason}

                        >
                          <VStack align='left'>
                            <Radio value='1'>I am not available this time</Radio>
                            <Radio value='2'>This topic is out of my area</Radio>
                            <Radio value='0'>Other reasons: </Radio>
                          </VStack>
                        </RadioGroup>
                        <Input
                          id={'reason_input_' + item._id}
                          placeholder="Enter Other reasons ..."
                        />
                        <Box h='3'></Box>
                        <Button
                          colorScheme='blue'
                          onClick={() => handleEditorDecline(item._id)}>SEND</Button>
                      </Box>
                    </PopoverBody>
                  </PopoverContent>

                </Popover>

              </>
            );

          case EDITOR_STATUSES.INCOMPLETE_ASSIGNMENT:
          case EDITOR_STATUSES.WAIT_FOR_AUTHOR_REVISE:
          case EDITOR_STATUSES.AUTHOR_SENT_BACK_REVISED:
            reviewData = getAllReviewData(item);

            return (
              <>
                <Button
                  onClick={() => hanldeDownload(item)}
                  size="xs"
                  mt="1"
                  colorScheme="blue"
                >
                  View File
                </Button>

                <Button
                  size="xs"
                  mt="1"
                  colorScheme="blue"
                  onClick={() => handleOpenModalSendResultChief(item)}
                >
                  Send result to Editor-in-Chief
                </Button>

                <Box>
                  <Button
                    size="xs"
                    mt="1"
                    colorScheme="green"
                    onClick={() => handleEditorInviteReviewers(item)}
                  >
                    Invite Reviewer
                  </Button>
                  &nbsp;
                  <Popover placement='top-start' matchWidth={true}>
                    <PopoverTrigger>
                      <Button
                        size="xs"
                        mt="1"
                        colorScheme="green"
                      >
                        ({reviewData.reviewerAccepted}, {reviewData.reviewerRejected})
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton bg='red.500' color='white' />
                      <PopoverHeader textAlign='left' fontWeight='semibold'>Invited Reviewers</PopoverHeader>
                      <PopoverBody textAlign='left'>
                        {reviewData.allReview.map((review, idx) => (
                          <Box pb='3'>
                            {idx + 1}. {review.reviewer.title}. {review.reviewer.lastname} {review.reviewer.firstname} - {review.status.toUpperCase()}
                            <hr />
                          </Box>
                        ))}
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Box>

                <Button
                  size="xs"
                  mt="1"
                  colorScheme="red"
                  onClick={() => viewResultReview(item._id)}
                >
                  View Reviewer results ({reviewData.reviewerCompleted}/{reviewData.reviewerAccepted})
                </Button>
                <Button
                  size="xs"
                  mt="1"
                  colorScheme="yellow"
                  onClick={() => {
                    handleOpenModalRevisionToAuthor(item);
                  }}
                >
                  Send back to author
                </Button>
              </>
            );

          case EDITOR_STATUSES.REJECTED:
            return (
              <>
                <Button
                  onClick={() => hanldeDownload(item)}
                  size="xs"
                  mt="1"
                  colorScheme="blue"
                >
                  View File
                </Button>
              </>
            );

          case EDITOR_STATUSES.COMPLETED:
            return (
              <>
                <Button
                  onClick={() => hanldeDownload(item)}
                  size="xs"
                  mt="1"
                  colorScheme="blue"
                >
                  View File
                </Button>
              </>
            );
        }

      //  role PUBLISHER
      case ROLES.PUBLISHER:
        switch (item.publisherStatus) {
          case PUBLISHER_STATUSES.WAIT_FOR_PUBLISHING:
          case PUBLISHER_STATUSES.WAIT_FINAL_CHECK:
            return (
              <>
                <Button
                  onClick={() => hanldeDownload(item)}
                  size="xs"
                  mt="1"
                  colorScheme="blue"
                >
                  View File
                </Button>

                <Link to={`/articles/${item._id}/edit?type=publisher`}>
                  <Button size="xs" colorScheme="yellow">
                    Edit Submission
                  </Button>
                </Link>
                <Button
                  hidden={ item.publisherStatus === PUBLISHER_STATUSES.WAIT_FINAL_CHECK }
                  size="xs"
                  mt="1"
                  colorScheme="orange"
                  onClick={() => handleOpenModalSendAuthorFinalCheck(item)}
                >
                  Send Author Final Check
                </Button>
                <Button
                  size="xs"
                  mt="1"
                  colorScheme="green"
                  onClick={() => handlePublisherAccept(item)}
                >
                  Publish
                </Button>
              </>
            );

          case PUBLISHER_STATUSES.PUBLISHED:
            return (
              <>
                <Button
                  onClick={() => hanldeDownload(item)}
                  size="xs"
                  mt="1"
                  colorScheme="blue"
                >
                  View File
                </Button>

                <Link to={`/articles/${item._id}/edit?type=publisher`}>
                  <Button size="xs" colorScheme="yellow">
                    Edit Submission
                  </Button>
                </Link>

                {user.role % ROLES.ADMIN === 0 && (<Button
                  size="xs"
                  mt="1"
                  colorScheme="red"
                  onClick={() => handleRemoveSubmission(item._id)}
                  
                >
                  Remove Submission
                </Button>
                )}

                <Button
                  size="xs"
                  mt="1"
                  colorScheme="green"
                  onClick={() => handlePublisherAccept(item)}
                >
                  Edit Publish Info
                </Button>
              </>
            );
        }

      default:
        return;
    }
  };

  const renderList = () => {
    switch (+role) {
      case ROLES.AUTHOR:
        return articles
          .filter(
            (article) =>
              (statusSelected ? article.status === statusSelected : true) &&
              (searchQuery
                ? new RegExp(searchQuery, "gi").test(article.info?.title) ||
                new RegExp(searchQuery, "gi").test(article.manuscriptId) ||
                new RegExp(searchQuery, "gi").test(article.author?.email) ||
                new RegExp(searchQuery, "gi").test(
                  article.info?.authors
                    .map((author) => `${author.lastname} ${author.firstname}`)
                    .join(";")
                )
                : true)
          )
          .map((item, idx) => (
            <Tr key={item._id}>
              <Td>{idx + 1}</Td>
              <Td maxW="20">{item.manuscriptId}</Td>
              <Td>
                <Box className="two-line-text">{item.info?.title}</Box>
              </Td>
              <Td>{timestampToDate(item.submissionDate)}</Td>
              <Td>{timestampToDate(item.submissionDate)}</Td>
              <Td textAlign="center">{checkStatus(item.status)}</Td>
              <Td isNumeric>
                <VStack textAlign="center">
                  <PopupListMail 
                    article={item}
                  />
                  {renderActionLinksByRole(item)}
                </VStack>
              </Td>
            </Tr>
          ));

      case ROLES.REVIEWER:
        return articles
          .filter(
            (article) =>
              (statusSelected ? article.status === statusSelected : true) &&
              (searchQuery
                ? new RegExp(searchQuery, "gi").test(
                  article.article?.info?.title
                ) ||
                new RegExp(searchQuery, "gi").test(
                  article.article?.manuscriptId
                ) ||
                new RegExp(searchQuery, "gi").test(
                  article.article?.author?.email
                ) ||
                new RegExp(searchQuery, "gi").test(
                  article.info?.authors
                    .map((author) => `${author.lastname} ${author.firstname}`)
                    .join(";")
                )
                : true)
          )
          .map((item, idx) => (
            <Tr key={item._id}>
              <Td>{idx + 1}</Td>
              <Td>{item.article?.manuscriptId}</Td>
              <Td>
                <Box className="two-line-text">{item.article?.info?.title}</Box>
              </Td>
              <Td>{timestampToDate(item.createdAt)}</Td>
              <Td>{timestampToDate(item.submissionDate)}</Td>
              <Td textAlign="center">{checkStatus(item.status)}</Td>
              <Td>
                <VStack textAlign="center">
                  <PopupListMail 
                    article={item.article}
                  />
                  {renderActionLinksByRole(item)}
                </VStack>
              </Td>
            </Tr>
          ));

      case ROLES.EDITOR_IN_CHIEF:
        return articles
          .filter(
            (article) =>
              (statusSelected
                ? article.editorInChiefStatus === statusSelected
                : true) &&
              (searchQuery
                ? new RegExp(searchQuery, "gi").test(article.info?.title) ||
                new RegExp(searchQuery, "gi").test(article.manuscriptId) ||
                new RegExp(searchQuery, "gi").test(article.author?.email) ||
                new RegExp(searchQuery, "gi").test(
                  article.info?.authors
                    .map((author) => `${author.lastname} ${author.firstname}`)
                    .join(";")
                )
                : true)
          )
          .map((item, idx) => (
            <Tr key={item._id}>
              <Td>{idx + 1}</Td>
              <Td>{item.manuscriptId}</Td>
              <Td>
                <Box className="two-line-text">{item.info?.title}</Box>
              </Td>
              <Td>{item?.major}</Td>
              <Td>{item?.author?.lastname + ' ' + item?.author?.firstname}</Td>
              <Td>{timestampToDate(item.submissionDate)}</Td>
              <Td>{timestampToDate(item.dateDecision)}</Td>
              <Td textAlign="center">
                {checkStatus(item.editorInChiefStatus)}
              </Td>
              <Td isNumeric>
                <VStack textAlign="center">
                  <PopupListMail 
                    article={item}
                  />
                  {renderActionLinksByRole(item)}
                </VStack>
              </Td>
            </Tr>
          ));

      case ROLES.EDITOR:
        return articles
          .filter(
            (article) =>
              (statusSelected
                ? article.editorStatus === statusSelected
                : true) &&
              (searchQuery
                ? new RegExp(searchQuery, "gi").test(article.info?.title) ||
                new RegExp(searchQuery, "gi").test(article.manuscriptId) ||
                new RegExp(searchQuery, "gi").test(article.author?.email) ||
                new RegExp(searchQuery, "gi").test(
                  article.info?.authors
                    .map((author) => `${author.lastname} ${author.firstname}`)
                    .join(";")
                )
                : true)
          )
          .map((item, idx) => (
            <Tr key={item._id}>
              <Td>{idx + 1}</Td>
              <Td>{item.manuscriptId}</Td>
              <Td>
                <Box className="two-line-text">{item.info?.title}</Box>
              </Td>
              <Td>{item?.major}</Td>
              <Td>{item?.author?.lastname + ' ' + item?.author?.firstname}</Td>
              <Td>{timestampToDate(item.submissionDate)}</Td>
              <Td>{timestampToDate(item.dateDecision)}</Td>
              <Td textAlign="center">{checkStatus(item.editorStatus)}</Td>
              <Td isNumeric>
                <VStack textAlign="center">
                  <PopupListMail 
                    article={item}
                  />
                  {renderActionLinksByRole(item)}
                </VStack>
              </Td>
            </Tr>
          ));

      case ROLES.PUBLISHER:
        return articles
          .filter(
            (article) =>
              (statusSelected
                ? article.publisherStatus === statusSelected
                : true) &&
              (searchQuery
                ? new RegExp(searchQuery, "gi").test(article.info?.title) ||
                new RegExp(searchQuery, "gi").test(article.manuscriptId) ||
                new RegExp(searchQuery, "gi").test(article.author?.email) ||
                new RegExp(searchQuery, "gi").test(
                  article.info?.authors
                    .map((author) => `${author.lastname} ${author.firstname}`)
                    .join(";")
                )
                : true)
          )
          .map((item, idx) => (
            <Tr key={item._id}>
              <Td>{idx + 1}</Td>
              <Td>{item.manuscriptId}</Td>
              <Td>
                <Box className="two-line-text">{item.info?.title}</Box>
              </Td>
              <Td>{item?.major}</Td>
              <Td>{item?.author?.lastname + ' ' + item?.author?.firstname}</Td>
              <Td>{timestampToDate(item.submissionDate)}</Td>
              <Td textAlign="center">{checkStatus(item.publisherStatus)}</Td>
              <Td isNumeric>
                <VStack textAlign="center">
                  <PopupListMail 
                    article={item}
                  />
                  {renderActionLinksByRole(item)}
                </VStack>
              </Td>
            </Tr>
          ));

      default:
        return;
    }
  };

  const renderHeader = () => {
    switch (+role) {
      case ROLES.AUTHOR:
        return (
          <>
            <Th textAlign="center">No</Th>
            <Th textAlign="center">Manuscript ID</Th>
            <Th textAlign="center">Manuscript title</Th>
            <Th textAlign="center">Date of starting submission</Th>
            <Th textAlign="center">Date of last status</Th>
            <Th textAlign="center">Status</Th>
            <Th textAlign="center" isNumeric>Action links</Th>
          </>
        );

      case ROLES.REVIEWER:
        return (
          <>
            <Th>No</Th>
            <Th>Manuscript ID</Th>
            <Th>Manuscript title</Th>
            <Th>Date of invitation</Th>
            <Th>Date of review submission</Th>
            <Th textAlign="center">Status</Th>
            <Th isNumeric>Action links</Th>
          </>
        );

      case ROLES.EDITOR_IN_CHIEF:
      case ROLES.EDITOR:
        return (
          <>
            <Th>No</Th>
            <Th>Manuscript ID</Th>
            <Th>Manuscript title</Th>
            <Th>Research area</Th>
            <Th>Author</Th>
            <Th>Date of submission</Th>
            <Th>Date of Decision</Th>
            <Th textAlign="center">Status</Th>
            <Th isNumeric>Action links</Th>
          </>
        );

      case ROLES.PUBLISHER:
        return (
          <>
            <Th>No</Th>
            <Th>Manuscript ID</Th>
            <Th>Manuscript title</Th>
            <Th>Research area</Th>
            <Th>Author</Th>
            <Th>Date of submission</Th>
            <Th textAlign="center">Status</Th>
            <Th isNumeric>Action links</Th>
          </>
        );
      default:
        return;
    }
  };

  const handleApproveReview = (review) => {
    handleUpdateReview(review, UpdateReviewActions.ApproveReview, true);
  };

  const handleRemoveSubmission = (articleId) => {
    if (!window.confirm("Are you OK?"))
      return;

    return axiosInstance
      .delete(`/articles/${articleId}`)
      .then((_) => {
        setRefresh((pre) => !pre);
        alert("Delete success");
      })
      .catch(console.log);
  };

  // EDITOR_IN_CHIEF
  const {
    isOpen: isOpenSendEmailModal,
    onOpen: onOpenSendMailModal,
    onClose: onCloseSendEmailModal,
  } = useDisclosure();
  const [currentModalTitle, setCurrentModalTitle] = useState(
    MODAL_TITLES.INVITE_EDITOR
  );
  const [currentModalArticle, setCurrentModalArticle] = useState();
  const [to, setTo] = useState("");
  const [cc, setCc] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");

  const handleOpenModal = (article) => {
    setTo('');
    setCc('');
    setSubject(`Editorial invitation on Manuscript ID ${article.manuscriptId}`);
    setContent(
      `Dear ....,

...*please type your content here*...

Sincerely,
EDITOR IN CHIEF`
    );

    setCurrentModalTitle(MODAL_TITLES.INVITE_EDITOR);
    setCurrentModalArticle(article);
    onOpenSendMailModal();
  };

  const handleOpenModalSendResultChief = async (article) => {
    let reviewContent = await getReviewContent(article, false);
    setTo(CONSTANTS_DATA.EMAIL_TBT);
    setCc('');
    setSubject(`Editorial result on Manuscript ID ${article.manuscriptId}`);
    setContent(
      `Dear ${CONSTANTS_DATA.FULLNAME_TBT},

...*please type your content here*...

${reviewContent}

Sincerely,
EDITOR`
    );

    setCurrentModalTitle(MODAL_TITLES.SEND_RESULT_TO_CHIEF);
    setCurrentModalArticle(article);
    onOpenSendMailModal();
  };

  const getDescRecommendation = (review) => {
    let desc = '';
    if (review.recommendation === 'Y')
      desc = 'Accept';
    else if (review.recommendation === 'R')
      desc = 'Revision';
    else if (review.recommendation === 'N')
      desc = 'Reject';

    return desc;
  }

  function sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  const handleOpenModalSendToPublisher = async (article) => {
    let reviewContent = await getReviewContent(article);
    let author = getCorrespondingAuthor(article);
    setTo(author.email);
    setCc('');
    setSubject(`Decision on Manuscript ID ${article.manuscriptId}`);
    setContent(
      `Dear ${author.lastname} ${author.firstname},

It is my pleasure to inform you that your manuscript entitled "${article.info.title}" has been accepted for publication in Journal of Transportation Science and Technology (JTST).
${GlobalVar.FRONTEND_URI}/articles/3/management

You will be contacted with your article proofs to check. When you receive your proofs for checking, please limit your changes to only correcting errors, updating out-of-date information, or substituting photographs, within 48 hours of receiving the proofs. Your paper will be published online at https://jtst.ut.edu.vn, a few days after we receive your final version.

We congratulate you on the acceptance of your manuscript for publication and should you have further questions or assistance, feel free to contact The Journal at: Journal of Transportation Science and Technology
no 2 Vo Oanh Street, Ward 25, Binh Thanh District, HCMC
t: 0335 814 479 (Ms. Mai)
e: tapchikhcngtvt@ut.edu.vn

Best wishes,
JTST, The Editorial Office.
`
    );

    setCurrentModalTitle(MODAL_TITLES.SEND_TO_PUBLISHER);
    setCurrentModalArticle(article);
    onOpenSendMailModal();
  };

  const handleOpenModalRevisionToAuthor = async (article) => {
    let reviewContent = await getReviewContent(article);
    let author = getCorrespondingAuthor(article);
    setTo(author.email);
    setCc('');
    setSubject(`Need Revise/Change on Manuscript ID ${article.manuscriptId}`);
    setContent(
      `Dear ${author.lastname} ${author.firstname},

...*please type your content here*...
${reviewContent}

Notes: In case of revison, The Author resubmit the revised manuscript in the system ( ${GlobalVar.FRONTEND_URI}/articles/3/management ) and add your comments, explaination in the end (next page) of the revised manuscript.

Sincerely,
JTST, Editorial Office`
    );

    setCurrentModalTitle(MODAL_TITLES.REVISION_AUTHOR);
    setCurrentModalArticle(article);
    onOpenSendMailModal();
  };

  const handleOpenModalSendAuthorFinalCheck = async (article) => {
    let author = getCorrespondingAuthor(article);
    setTo(author.email);
    setCc('');
    setSubject(`Paper check on Manuscript ID ${article.manuscriptId}`);
    setContent(
      `Dear ${author.lastname} ${author.firstname},

Congratulations! Your manuscript with tittle "${article.info.title}" is nearing publication. Please take a moment to check that everything is correct. You can reply to this email if there is a problem within 2 days (Any updating will not be processed after this time). Note that at this stage we will not accept further changes to the manuscript text.

Thanks again for choosing Journal of Transportation Science and Technology to publish your work. We look forward to receiving further contributions from your research group in the future.

Kind regards,
Production Editor
E-Mail: ngocmai.nguyen@ut.edu.vn`
    );

    setCurrentModalTitle(MODAL_TITLES.SEND_AUTHOR_FINAL_CHECK);
    setCurrentModalArticle(article);
    onOpenSendMailModal();
  };

  const handleOpenModalReplyPublisherFinalCheck = async (article) => {
    let author = getCorrespondingAuthor(article);
    setTo(CONSTANTS_DATA.EMAIL_PUBLISHER);
    setCc(author.email);
    setSubject(`Paper check on Manuscript ID ${article.manuscriptId}`);
    setContent(
      `Dear Production Editor of JTST,

... YOUR CONTENT TO REPLY (Ex: I agree to publish and no changes / I fixed some highlights in the resend file)
...

Kind regards,
`
    );

    setCurrentModalTitle(MODAL_TITLES.REPLY_PUBLISHER_FINAL_CHECK);
    setCurrentModalArticle(article);
    onOpenSendMailModal();
  };

  const handleCloseModal = () => {
    setCurrentModalArticle(undefined);
    onCloseSendEmailModal();
  };

  //EDITOR
  const handleRejectDecision = async (article) => {
    let reviewContent = await getReviewContent(article);
    let author = getCorrespondingAuthor(article);
    setTo(author.email);
    setCc('');
    setSubject(`Decision on Manuscript ID ${article.manuscriptId}`);
    setContent(
      `Dear ${author.lastname} ${author.firstname},

I regret to inform you that based on the enclosed reviews and my own reading of your manuscript entitled "${article.info.title}", I am unable to recommend its publication in JTST.
${reviewContent}

Sincerely,
JTST, Editorial Office`
    );

    setCurrentModalTitle(MODAL_TITLES.EDITOR_IN_CHIEF_REJECT);
    setCurrentModalArticle(article);
    onOpenSendMailModal();


    // if (!window.confirm("Are you OK?"))
    //   return;

    // return axiosInstance
    //   .put(`/articles/${articleId}/reject`)
    //   .then((_) => {
    //     setRefresh((pre) => !pre);
    //   })
    //   .catch(console.log);
  };

  //EDITOR
  const handleEditorAccept = (articleId) => {
    if (!window.confirm("Are you OK?"))
      return;

    return axiosInstance
      .get(`/articles/${articleId}/invite/editor`)
      .then((_) => {
        setRefresh((pre) => !pre);
      })
      .catch(console.log);
  };

  const handleEditorDecline = (articleId) => {
    let reason_input_value = document.getElementById("reason_input_" + articleId).value;
    if (declineReason === '1')
      reason_input_value = 'I am not available this time';
    else if (declineReason === '2')
      reason_input_value = 'This topic is out of my area';
    // console.log(declineReason)
    // console.log(reason_input_value)
    if (!(declineReason in ['0', '1', '2'])
      || (declineReason === '0' && reason_input_value.length <= 0)) {
      alert('!!! Please choose REASON to decline invitation !!!');
      return;
    }

    if (window.confirm("Do you want to decline this?")) {
      return axiosInstance
        .put(`/articles/${articleId}/decline/editor`, { declineReason: reason_input_value })
        .then((_) => {
          setRefresh((pre) => !pre);
        })
        .catch(console.log);
    }
  };

  const handleEditorInviteReviewers = (article) => {
    setTo('');
    setCc('');
    setSubject(`Review invitation on Manuscript ID ${article.manuscriptId}`);
    setContent(
      `Dear ...,

...*please type your content here*...

Sincerely,
EDITOR`
    );

    setCurrentModalTitle(MODAL_TITLES.INVITE_REVIEWER);
    setCurrentModalArticle(article);
    onOpenSendMailModal();
  };

  //PUBLISHER
  const handlePublisherAccept = (article) => {
    setCurrentModalTitle(MODAL_TITLES.PUBLISH_ARTICLE);
    setCurrentModalArticle(article);
    onOpenSendMailModal();

    // return axiosInstance
    //   .get(`/articles/${articleId}/invite/publisher`)
    //   .then((_) => {
    //     setRefresh((pre) => !pre);
    //   })
    //   .catch(console.log);
  };

  return !loading ? (
    <Box>
      <Modal
        size="2xl"
        isOpen={openReviewResultModal}
        onClose={() => {
          setOpenReviewResultModal(false);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Review results</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="stretch" spacing="4">
              {reviewResults?.length &&
                reviewResults.map((review, idx) => (
                  <Box>
                    <Box fontSize="xl"><b>{review.reviewer.title}. {review.reviewer.lastname} {review.reviewer.firstname} ({review.reviewer.email}):</b></Box>
                    <List spacing={2}>
                      <ListItem>
                        <ListIcon as={MdCheckCircle} color='blue.500' />
                        Relevance and timeliness: {review.scores?.reievance}
                      </ListItem>
                      <ListItem>
                        <ListIcon as={MdCheckCircle} color='blue.500' />
                        Technical content and scientific rigour: {review.scores?.technicalContent}
                      </ListItem>
                      <ListItem>
                        <ListIcon as={MdCheckCircle} color='blue.500' />
                        Novelty and originality: {review.scores?.novelty}
                      </ListItem>
                      <ListItem>
                        <ListIcon as={MdCheckCircle} color='blue.500' />
                        Quality of presentation: {review.scores?.quality}
                      </ListItem>

                      <ListItem>
                        <ListIcon as={MdCheckCircle} color='blue.500' />
                        Comment to author:<br />{review.strongAspects}
                      </ListItem>
                      <ListItem>
                        <ListIcon as={MdCheckCircle} color='blue.500' />
                        Comment confidential to Editor:<br />{review.weakAspects}
                      </ListItem>

                      <FileReviewComponent review={review}/>

                      <ListItem>
                        <ListIcon as={MdCheckCircle} color='blue.500' />
                        Recommendation: <strong>{getDescRecommendation(review)}</strong>
                      </ListItem>
                    </List>
                    <hr />
                  </Box>
                ))}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* review modal */}
      <Modal
        size="6xl"
        isOpen={openReviewModal}
        onClose={() => {
          setOpenReviewModal(false);
          setViewReviewSelected(false);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Review article</ModalHeader>
          <ModalCloseButton />
          {reviewSelected && (
            <ModalBody>
              <VStack align="stretch" spacing="6">
                <Grid templateColumns="repeat(6, 1fr)" gap="2">
                  <GridItem textAlign="left" fontWeight="semibold">
                    Title:{" "}
                  </GridItem>
                  <GridItem colSpan="5">
                    {reviewSelected.article?.info.title}
                  </GridItem>
                  {/* <GridItem textAlign="right">Chu de nghien cuu</GridItem>
                <GridItem colSpan="3">
                  {reviewSelected.article?.researches.toString()}
                </GridItem> */}
                  <GridItem textAlign="left" fontWeight="semibold">
                    Manuscript:{" "}
                  </GridItem>
                  <GridItem
                    colSpan="5"
                    textDecor="underline"
                    cursor="pointer"
                    color="blue"
                    // onClick={() => hanldeDownload(reviewSelected.article)}
                  >
                    <ViewFileButton article={reviewSelected.article} isReviewer={true} />
                  </GridItem>
                </Grid>
                <hr/>
                <HStack spacing={4}>
                  {['5 - excellent', '4 - very good', '3 - good', '2 - average', '1 - not good'].map((desc) => (
                    <Tag size={'md'} key={desc} variant='solid' colorScheme='blue'>
                      {desc}
                    </Tag>
                  ))}
                </HStack>

                <Grid templateColumns="repeat(2, 1fr)" gap="8" textAlign="left">
                  <GridItem >
                    <HStack>
                      <Box w="20">
                        <Select colorScheme='blue'
                          value={reviewScores.reievance}
                          onChange={(e) =>
                            setReviewScores((pre) => ({
                              ...pre,
                              reievance: e.target.value,
                            }))
                          }
                          isDisabled={viewReviewSelected}
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </Select>
                      </Box>
                      <Box textAlign="center">Relevance and timeliness</Box>
                    </HStack>
                  </GridItem>

                  <GridItem >
                    <HStack>
                      <Box w="20">
                        <Select
                          value={reviewScores.technicalContent}
                          onChange={(e) =>
                            setReviewScores((pre) => ({
                              ...pre,
                              technicalContent: e.target.value,
                            }))
                          }
                          isDisabled={viewReviewSelected}
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </Select>
                      </Box>
                      <Box textAlign="center">
                        Technical content and scientific rigour
                      </Box>
                    </HStack>
                  </GridItem>

                  <GridItem >
                    <HStack>
                      <Box w="20">
                        <Select
                          value={reviewScores.novelty}
                          onChange={(e) =>
                            setReviewScores((pre) => ({
                              ...pre,
                              novelty: e.target.value,
                            }))
                          }
                          isDisabled={viewReviewSelected}
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </Select>
                      </Box>
                      <Box textAlign="center">Novelty and originality</Box>
                    </HStack>
                  </GridItem>

                  <GridItem >
                    <HStack>
                      <Box w="20">
                        <Select
                          value={reviewScores.quality}
                          onChange={(e) =>
                            setReviewScores((pre) => ({
                              ...pre,
                              quality: e.target.value,
                            }))
                          }
                          isDisabled={viewReviewSelected}
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </Select>
                      </Box>
                      <Box textAlign="center">Quality of presentation</Box>
                    </HStack>
                  </GridItem>
                </Grid>

                <Grid templateColumns="repeat(2, 1fr)" gap="8" textAlign="left">
                  <GridItem >
                    <Box>
                      <Box>
                        <strong>Comment to author <span className="required-field">(*)</span></strong>
                      </Box>
                      <Textarea
                        border={'2px'}
                        value={strongAspects}
                        onChange={(e) => setStrongAspects(e.target.value)}
                        minH="64"
                        isDisabled={viewReviewSelected}
                      />
                    </Box>
                  </GridItem>

                  <GridItem >
                    <Box>
                      <Box>
                        <strong>Comment confidential to Editor</strong>
                      </Box>
                      <Textarea
                        border={'2px'}
                        value={weakAspects}
                        onChange={(e) => setWeakAspects(e.target.value)}
                        minH="64"
                        isDisabled={viewReviewSelected}
                      />
                    </Box>
                  </GridItem>
                  <Box hidden={true}>
                    <Box>
                      Recommended changes{" "}
                      <span className="required-field">(*)</span>
                    </Box>
                    <Textarea
                      value={recommendedChanges}
                      onChange={(e) => setRecommendedChanges(e.target.value)}
                      minH="32"
                      isDisabled={viewReviewSelected}
                    />
                  </Box>
                  <GridItem >
                    <Box>
                      <Box>
                        <strong>Attach file{" "}</strong>
                      </Box>
                      
                      <Box>
                        <Link 
                          to={`/download-file/${reviewSelected._id}/${getFileNameOut(reviewSelected?.fileReview)}`}
                          target='_blank'
                          hidden={!reviewSelected?.fileReview}
                        >
                          <Box
                            colSpan="5"
                            textDecor="underline"
                            cursor="pointer"
                            color="blue"
                            onClick={() => {}}
                          >
                            {getFileNameOut(reviewSelected?.fileReview)}
                          </Box>
                        </Link>
                                                
                        <input 
                          hidden = {viewReviewSelected}
                          type="file" 
                          accept={'.doc, .docx, .pdf, .txt, .png, .jpg'}
                          onChange={(e) => {
                            setFileReview(e.target.files[0]);
                          }}
                        />
                      </Box>
                    </Box>
                  </GridItem>

                  <GridItem >
                    <Box fontWeight={'bold'}>
                      <Box>
                        Recommendation{" "}
                        <span className="required-field">(*)</span>
                      </Box>
                      <Box alignItems={'center'}>
                        <RadioGroup
                          onChange={setRecommendation}
                          value={recommendation}
                        >
                          <HStack spacing={6} align='left'>
                            <Radio isDisabled={viewReviewSelected} value='Y'>Accept</Radio>
                            <Radio isDisabled={viewReviewSelected} value='R'>Revision</Radio>
                            <Radio isDisabled={viewReviewSelected} value='N'>Reject</Radio>
                          </HStack>
                        </RadioGroup>
                      </Box>
                    </Box>
                  </GridItem>
                </Grid>

              </VStack>
            </ModalBody>
          )}
          {!viewReviewSelected && (
            <ModalFooter>
              <Button
                colorScheme="gray"
                mr={3}
                onClick={() => hanldeReviewArticle(reviewSelected)}
              >
                Save
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => handleApproveReview(reviewSelected)}
              >
                Approve review
              </Button>
              
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>

      <SendEmailModal
        article={currentModalArticle}
        title={currentModalTitle}
        isOpen={isOpenSendEmailModal}
        onClose={handleCloseModal}
        setRefresh={setRefresh}
        in_to={to}
        in_cc={cc}
        in_subject={subject}
        in_content={content}
      />

      <HStack mb="4">
        <Box>Select role</Box>
        <Box>
          <Select
            style={{ textTransform: "uppercase" }}
            onChange={(e) => {
              history.push(`/articles/${e.target.value}/management`);
            }}
            value={role}
          >
            {Object.keys(ROLES)
              .filter((r) => user.role % ROLES[r] === 0)
              .map((r, idx) => (
                <option value={ROLES[r]} key={idx}>
                  {r}
                </option>
              ))}
          </Select>
        </Box>
      </HStack>

      <HStack>
        <Box maxW="80" minW="80">
          <Input
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search"
          />
        </Box>

        <Box>
          <Select
            style={{
              textTransform: "uppercase",
            }}
            value={statusSelected}
            onChange={(e) => setStatusSelected(e.target.value)}
          >
            <option value="">All</option>
            {Object.values(currentStatuses).map((status) => (
              <option value={status}>{status}</option>
            ))}
          </Select>
        </Box>
      </HStack>

      <Table>
        <Thead>
          <Tr textAlign="center">{renderHeader()}</Tr>
        </Thead>
        <Tbody>{renderList()}</Tbody>
      </Table>
    </Box>
  ) : (
    <Box>Loading ...</Box>
  );
};

export default withRole(Reviews, [
  ROLES.AUTHOR,
  ROLES.EDITOR,
  ROLES.REVIEWER,
  ROLES.PUBLISHER,
  ROLES.EDITOR_IN_CHIEF,
]);
