import React, { useEffect } from "react";
import {
  Box,
  Button,
  HStack,
  Table,
  Tbody,
  Td,
  Tr,
  VStack,
  Grid
} from "@chakra-ui/react";
import RightMenu from "components/UI/RightMenu";

const EditorialBoard = () => {
  const IsMobile = false;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
  <HStack align="flex-start" spacing="4">
    <Box w="100%">
      {/* <Box fontWeight='bold' pb="7">
        EDITORS
      </Box> */}
      <Box pb="5">
        <p><strong>Editor-in-chief</strong></p>
        <p>Dr. Nguyễn Tiến Thuỷ</p>
        <p>Ho Chi Minh City University of Transport</p>
      </Box>

      <Box pb="5">
        <p><strong>Editor Assistant</strong></p>
        <Grid templateColumns="repeat(2, 1fr)" gap="4" placeItems="right">
          <Box>
            <p>Dr. Nguyễn Quang Sang</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Dr. Đoàn Văn Đổng</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
        </Grid>
      </Box>

      <Box pb="5">
        <p><strong>Editorial Board</strong></p>
        <Grid templateColumns="repeat(2, 1fr)" gap="4" placeItems="right">
          <Box>
            <p>Assoc. Prof. Nguyễn Xuân Phương</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
        
          <Box>
            <p>Prof. Vương Thanh Sơn</p>
            <p>University of British Columbia, Canada</p>
          </Box>
          <Box>
            <p>Prof. Lê Anh Tuấn</p>
            <p>Hanoi University of Science and Technology</p>
          </Box>
          <Box>
            <p>Assoc. Prof. Phạm Thị Anh</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Assoc. Prof. Vũ Ngọc Bích</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Assoc. Prof. Phan Văn Ca</p>
            <p>Ho Chi Minh City University of Technology and Education</p>
          </Box>
          <Box>
            <p>Assoc. Prof. Hồ Thị Thu Hòa</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Assoc. Prof. Nguyễn Phùng Hưng</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Assoc. Prof. Đồng Văn Hướng</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Assoc. Prof. Đặng Xuân Kiên</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Assoc. Prof. Trần Quang Phú</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Assoc. Prof. Võ Công Phương</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Assoc. Prof. Trương Ngọc Sơn</p>
            <p>Ho Chi Minh City University of Technology and Education</p>
          </Box>
          <Box>
            <p>Dr. Hoàng Bắc An</p>
            <p>University of Architecture Ho Chi Minh City</p>
          </Box>
          <Box>
            <p>Dr. Lê Bá Anh</p>
            <p>University of Transport and Communications</p>
          </Box>
          <Box>
            <p>Dr. Lê Văn Quốc Anh</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Dr. Nguyễn Tuấn Anh</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Dr. Vũ Thị Lan Anh</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Dr. Đỗ Hùng Chiến</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Dr. Phạm Tiến Cường</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Dr. Trần Trung Duy</p>
            <p>Posts and Telecommunications Institute of Technology - HCMC</p>
          </Box>
          <Box>
            <p>Assoc. Prof. Bùi Mạnh Hà</p>
            <p>Saigon University</p>
          </Box>
          <Box>
            <p>Dr. Trương Thanh Hải</p>
            <p>University of Architecture Ho Chi Minh City</p>
          </Box>
          <Box>
            <p>Dr. Nguyễn Văn Long</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Dr. Nguyễn Phi Long</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Dr. Vũ Văn Nghi</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Dr. Nguyễn Thành Sa</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Dr. Nguyễn Ngọc Sơn</p>
            <p>Industrial University Of HoChiMinh City</p>
          </Box>
          <Box>
            <p>Dr. Trần Thiên Thanh</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Dr. Nguyễn Anh Tuấn</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>Dr. Phạm Ngọc Tuấn</p>
            <p>University of Architecture Ho Chi Minh City</p>
          </Box>
          <Box>
            <p>Dr. Lê Văn Vang</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
          <Box>
            <p>MSc. Nguyễn Quang Thành</p>
            <p>Ho Chi Minh City University of Transport</p>
          </Box>
        </Grid>
      </Box>
    </Box>
    {!IsMobile && 
        <RightMenu/>}
  </HStack>

)};

export default EditorialBoard;
