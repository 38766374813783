import { Button } from "@chakra-ui/button";
import { Input } from "@chakra-ui/input";
import { Box, HStack, VStack, Grid } from "@chakra-ui/layout";
import { Collapse } from "@chakra-ui/react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Select } from "@chakra-ui/select";
import { Textarea } from "@chakra-ui/textarea";
import { GlobalContext } from "context/GlobalContext";
import { MODAL_TITLES } from "keys";
import React, { useContext, useEffect, useState } from "react";
import { axiosInstance } from "utils/axios";
import GlobalVar from 'utils/config'
import { useDisclosure } from "@chakra-ui/hooks";
import { EditIcon, ArrowUpIcon } from '@chakra-ui/icons'

function SendEmailModal({ article, title, isOpen, onClose, setRefresh, in_to='', in_cc='', in_subject='', in_content='' }) {
  const { volumes, user } = useContext(GlobalContext);

  const [publicationCode, setPublicationCode] = useState("");
  const [doiLink, setDoiLink] = useState("");
  const [pageNumberStart, setPageNumberStart] = useState("");
  const [pageNumberEnd, setPageNumberEnd] = useState("");
  const [selectedVolume, setSelectedVolume] = useState();
  const [selectedIssue, setSelectedIssue] = useState();

  const [to, setTo] = useState(in_to);
  const [cc, setCc] = useState(in_cc);
  const [subject, setSubject] = useState(in_subject);
  const [content, setContent] = useState(in_content);
  const [signUser, setSignUser] = useState(`--\n${user.title}. ${user.lastname} ${user.firstname}\nEmail: ${user.email}`);
  const [currentArticle, setCurrentArticle] = useState(article);
  const [fileAttachUploaded, setFileAttachUploaded] = useState();

  useEffect(() => {
    if (volumes?.length) {
      setSelectedVolume(volumes[0]);
    }
  }, [volumes]);

  useEffect(() => {
    if (selectedVolume?.issues?.length) {
      setSelectedIssue(selectedVolume?.issues[0]?._id);
    }
  }, [selectedVolume]);

  const handleChangeSelectedVolume = (id) => {
    const volume = volumes.find((v) => v._id === id);
    if (volume) {
      setSelectedVolume(volume);
    }
  };

  useEffect(() => {
    setPublicationCode(article?.manuscriptId);
    setDoiLink(article?.doiLink);
    setPageNumberStart(article?.pageNumberStart);
    setPageNumberEnd(article?.pageNumberEnd);

    handleChangeSelectedVolume(article?.volume 
                                ? article?.volume 
                                : (volumes?.length ? volumes[0] : undefined));
    setSelectedIssue(article?.issue 
                      ? article?.issue 
                      : (selectedVolume?.issues?.length ? selectedVolume?.issues[0]?._id : undefined));
    setCurrentArticle(article);
    setFileAttachUploaded(undefined);
  }, [article]);


  useEffect(() => {
    setTo(in_to);
    setCc(in_cc);
    setSubject(in_subject);
    setContent(in_content);
  }, [in_to, in_cc, in_subject, in_content]);

  const resetState = () => {
    setTo("");
    setCc("");
    setSubject("");
    setContent("");
  };

  const getFileNameOut = (fileData) => {
    let ext = fileData?.originalname.substring(fileData?.originalname.lastIndexOf(".")+1);
    let yyyymmdd = new Date().toISOString().slice(0,10).replace(/-/g,"");
    let fileNameOut = `JTST_${yyyymmdd}_${fileData?.filename}.${ext}`;

    return fileNameOut;
  }

  const handleSendInvite = () => {
    const reEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!reEmail.test(String(to).toLowerCase())) {
      return alert("To is invalid email");
    }

    if (
      cc
        .split(",")
        .filter((e) => !!e)
        .some((e) => !reEmail.test(e.trim()))
    ) {
      return alert(
        "CC is invalid list email. Example a@email.com, b@email.com"
      );
    }

    if (!subject.trim() || !content.trim()) {
      return alert("Fill all required field");
    }

    // if (!fileAttachUploaded) {
    //   return alert("You choosen file attach but NOT YET UPLOAD.\n Please click Upload button to Upload file Attach!");
    // }

    let finalContent = content;
    if (fileAttachUploaded)
      finalContent += `\n<strong>ATTACH FILE: ${GlobalVar.FRONTEND_URI}/download-file-public/${fileAttachUploaded?.fileForUpload.filename}/${getFileNameOut(fileAttachUploaded?.fileForUpload)}</strong>\n`;

    if (window.confirm("Are you OK?")) 
    {
      axiosInstance
        .post(`/articles/${article._id}/invite`, {
          type: title,
          to: to,
          data: {
            cc,
            subject,
            content: isHideSignatureUser() ? finalContent : finalContent + '\n' + signUser,
            fileAttach: fileAttachUploaded ? `${GlobalVar.FRONTEND_URI}/download-file-public/${fileAttachUploaded?.fileForUpload.filename}/${getFileNameOut(fileAttachUploaded?.fileForUpload)}` : undefined
          },
        })
        .then((res) => {
          // console.log(res)
          setRefresh && setRefresh((pre) => !pre);
          // resetState();
          alert("Send mail success");
          onClose();
        })
        .catch((err) => {
          if (typeof err.response?.data === "string") {
            alert(err.response?.data);
          } else alert("Something went error");
        });
    }
  };

  const handlePublish = () => {
    // console.log('selectedVolume: ' + selectedVolume)
    // console.log('selectedIssue: ' + selectedIssue)
    if (
      !publicationCode ||
      !pageNumberStart ||
      !pageNumberEnd ||
      !selectedVolume ||
      !selectedIssue ||
      !doiLink
    ) {
      return alert("Fill all required field");
    }
    if (window.confirm("Are you OK?")) {
      axiosInstance
        .post(`/articles/${article._id}/publish`, {
          publicationCode,
          doiLink,
          pageNumberStart,
          pageNumberEnd,
          volume: selectedVolume,
          issue: selectedIssue,
        })
        .then((res) => {
          setRefresh && setRefresh((pre) => !pre);
          // resetState();
          alert("Publish success");
          onClose();
        })
        .catch((err) => {
          if (typeof err.response?.data === "string") {
            alert(err.response?.data);
          } else alert("Something went error");
        });
    }
  };

  const handleSavePublishInfo = () => {
    if (
      !publicationCode ||
      !pageNumberStart ||
      !pageNumberEnd ||
      !selectedVolume ||
      !selectedIssue
    ) {
      return alert("Fill all required field");
    }

    if (window.confirm("Are you OK?")) {
      axiosInstance
        .post(`/articles/${article._id}/publish_update`, {
          publicationCode,
          doiLink,
          pageNumberStart,
          pageNumberEnd,
          volume: selectedVolume,
          issue: selectedIssue,
        })
        .then((res) => {
          setRefresh && setRefresh((pre) => !pre);
          // resetState();
          alert("Edit Publish Info success");
          onClose();
        })
        .catch((err) => {
          if (typeof err.response?.data === "string") {
            alert(err.response?.data);
          } else alert("Something went error");
        });
    }
  };

  function handleSend() {
    switch (title) {
      case MODAL_TITLES.PUBLISH_ARTICLE:
        handlePublish();
        break;
      
      case MODAL_TITLES.INVITE_REVIEWER:
        if (currentArticle?.fileForReviewer)
          handleSendInvite();
        else
        {
          alert("Please add File Review for Reviewer!");
          return;
        }
        break;

      default:
        handleSendInvite();
        break;
    }
    onClose();
  }

  
  const handleUploadFile = (a, fileForUpload) => {
    const formData = new FormData();
    formData.append("fileForUpload", fileForUpload);
    axiosInstance
      .put(`/articles/${a?._id}/uploadFileToWeb`, formData)
      .then((res) => {
        alert("Upload File SUCCESS!");
        setFileAttachUploaded(res.data);
        setRefresh && setRefresh((pre) => !pre);
      })
      .catch((err) => console.log(err));
  };

  const handleUpdateFileForReviewer = (a, fileForReviewer) => {
    const formData = new FormData();
    formData.append("fileForReviewer", fileForReviewer);

    axiosInstance
      .put(`/articles/${a?._id}/updateFileForReviewer`, formData)
      .then((res) => {
        alert("Upload & Save File for Reviewer SUCCESS!");
        setCurrentArticle(res.data);
        setRefresh && setRefresh((pre) => !pre);
      })
      .catch((err) => console.log(err));
  };

  function isHideSignatureUser()
  {
    return (
      title === MODAL_TITLES.REVISION_AUTHOR 
      || title === MODAL_TITLES.EDITOR_IN_CHIEF_REJECT 
      || title === MODAL_TITLES.SEND_TO_PUBLISHER
      || title === MODAL_TITLES.SEND_AUTHOR_FINAL_CHECK
      || title === MODAL_TITLES.INVITE_REVIEWER
      );
  }

  function FileAttachComponent() {
    const { isOpen, onToggle } = useDisclosure();
    const [fileAttach, setFileAttach] = useState();
    return (
      <>
        <Box fontWeight='bold'>Attach file</Box>
        <Box textAlign='left' textDecor={fileAttachUploaded ? "underline" : "none"}
            cursor="pointer"
            color="blue">
          <a href={`/download-file-public/${fileAttachUploaded?.fileForUpload.filename}/${getFileNameOut(fileAttachUploaded?.fileForUpload)}`}
            target='_blank'
          >
            {fileAttachUploaded ? getFileNameOut(fileAttachUploaded?.fileForUpload) : "Not available"}
          </a>

          <Button onClick={onToggle} leftIcon={<EditIcon />} colorScheme='blue' ml="2">
            {fileAttachUploaded ? "Edit" : "Add"}
          </Button>

          <Collapse in={isOpen} animateOpacity>
            <Box
              p='10px'
              color='white'
              mt='4'
              bg='blue.400'
              rounded='md'
              shadow='md'
              textDecor="none"
            >
              <input 
                type="file" 
                accept={'.doc, .docx, .pdf, .txt, .png, .jpg, .zip, .rar'}
                onChange={(e) => {
                  setFileAttach(e.target.files[0]);
                }}
              />
              <Button onClick={() => {
                    if (!fileAttach) {
                      alert("Please select file to upload ...");
                      return;
                    }
                    const msgConfirm = fileAttachUploaded ? "Upload will replace old file. Are you sure?" : "Are you sure?";
                    if (window.confirm(msgConfirm)) {
                      handleUploadFile(currentArticle, fileAttach);
                    }
                  }
                } 
                leftIcon={<ArrowUpIcon />} colorScheme='orange' ml="2">
                Upload
              </Button>
            </Box>
          </Collapse>
        </Box>
      </>
    )
  }

  function FileForReviewerComponent() {
    const { isOpen, onToggle } = useDisclosure();
    const [fileForReviewer, setFileForReviewer] = useState();

    return (
      <>
        <Box fontWeight='bold'>File Review:</Box>
        <Box textAlign='left' textDecor={currentArticle?.fileForReviewer ? "underline" : "none"}
            cursor="pointer"
            color="blue">
          <a href={`/download-file-public/${currentArticle?.fileForReviewer?.filename}/${getFileNameOut(currentArticle?.fileForReviewer)}`}
            target='_blank'
          >
            {currentArticle?.fileForReviewer ? getFileNameOut(currentArticle?.fileForReviewer) : "Do not exist file for Reviewer. Please add file ..."}
          </a>

          <Button onClick={onToggle} leftIcon={<EditIcon />} colorScheme='blue' ml="2">
            {currentArticle?.fileForReviewer ? "Edit" : "Add"}
          </Button>

          <Collapse in={isOpen} animateOpacity>
            <Box
              p='10px'
              color='white'
              mt='4'
              bg='blue.400'
              rounded='md'
              shadow='md'
              textDecor="none"
            >
              <input 
                type="file" 
                accept={'.doc, .docx, .pdf, .txt, .png, .jpg'}
                onChange={(e) => {
                  setFileForReviewer(e.target.files[0]);
                }}
              />
              <Button onClick={() => {
                    if (!fileForReviewer) {
                      alert("Please select file to upload ...");
                      return;
                    }
                    const msgConfirm = currentArticle?.fileForReviewer ? "Upload & Save will replace old file. Are you sure?" : "Are you sure?";
                    if (window.confirm(msgConfirm)) {
                      handleUpdateFileForReviewer(currentArticle, fileForReviewer);
                    }
                  }
                } 
                leftIcon={<ArrowUpIcon />} colorScheme='orange' ml="2">
                Upload & Save
              </Button>
            </Box>
          </Collapse>
        </Box>
      </>
    )
  }

  const renderBody = () => {
    
    switch (title) {
      case MODAL_TITLES.PUBLISH_ARTICLE:
        return (
          <>
            <HStack>
              <Box><i>JTST link: </i></Box>
              <a target="_blank" href={`${GlobalVar.FRONTEND_URI}/articles/${article?._id}`}>
                <Box
                  textDecor="underline"
                  cursor="pointer"
                  color="blue"
                >
                  {GlobalVar.FRONTEND_URI}/articles/{article?._id}
                </Box>
              </a>
            </HStack>
            <HStack>
              <Box flex="1">
                <Box><i>Publication code (*)</i></Box>
                <Input
                  // placeholder="Publication code (*)"
                  value={publicationCode}
                  onChange={(e) => setPublicationCode(e.target.value)}
                />
              </Box>
              <Box flex="1">
                <Box><i>DOI link (*)</i></Box>
                <Input
                  value={doiLink}
                  onChange={(e) => setDoiLink(e.target.value)}
                />
              </Box>
            </HStack>
            
            <HStack>
              <Box flex="1">
                <Box><i>Page start (*)</i></Box>
                <Input
                // placeholder="Page start (*)"
                value={pageNumberStart}
                onChange={(e) => setPageNumberStart(e.target.value)}
              />
              </Box>

              <Box flex="1">
                <Box><i>Page end (*)</i></Box>
                <Input
                  placeholder="Page end (*)"
                  value={pageNumberEnd}
                  onChange={(e) => setPageNumberEnd(e.target.value)}
                />
              </Box>
            </HStack>
            <HStack>
              <Box flex="1">
                <Box><i>Volume (*)</i></Box>
                <Select
                  value={selectedVolume?._id}
                  onChange={(e) => handleChangeSelectedVolume(e.target.value)}
                >
                  {volumes.map((v) => (
                    <option key={v._id} value={v._id}>
                      {v.name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box flex="1">
                <Box><i>Issue (*)</i></Box>
                <Select
                  value={selectedIssue}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                >
                  {selectedVolume?.issues.map((i) => (
                    <option key={i._id} value={i._id}>
                      {i.name}
                    </option>
                  ))}
                </Select>
              </Box>
            </HStack>
          </>
        );

      default:
        return (
          <>
            <Grid templateColumns="6em 1fr" gap="2" textAlign='right' alignItems='center'>
              {title == MODAL_TITLES.INVITE_REVIEWER && <FileForReviewerComponent/>}

              <Box fontWeight='bold'>To (*)</Box>
              <Input
                placeholder="To"
                value={to}
                onChange={(e) => setTo(e.target.value)}
              />
              
              <Box fontWeight='bold'>CC</Box>
              <Input
                placeholder="CC (separated list by comma ,)"
                value={cc}
                onChange={(e) => setCc(e.target.value)}
              />
              <Box fontWeight='bold'>Subject (*)</Box>
              <Input
                placeholder="Subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              <Box fontWeight='bold'>Content (*)</Box>
              <Textarea
                h='80'
                minH="40"
                placeholder="Content"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />

              <FileAttachComponent/>

              <Box hidden={isHideSignatureUser()} 
                fontWeight='bold'>Signature</Box>
              <Textarea
                hidden={isHideSignatureUser()}
                isDisabled={true}
                value={signUser}
              />
            </Grid>
          </>
        );
    }
  };

  return (
    <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{article?.publishedDate ? 'Edit Publish Info' : title}</ModalHeader>
        <ModalCloseButton bg='tomato' />
        <ModalBody>
          <VStack align="stretch">{renderBody()}</VStack>
        </ModalBody>

        <ModalFooter>
          <Button hidden = {!article?.publishedDate} colorScheme="blue" mr={3} onClick={handleSavePublishInfo}>
            Save
          </Button>
          <Button hidden = {article?.publishedDate} colorScheme="blue" mr={3} onClick={handleSend}>
            Send
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default SendEmailModal;
