import React, { useEffect } from "react";
import {
  Box,
  Button,
  HStack,
  Table,
  Tbody,
  Td,
  Tr,
  VStack,
  Grid,
  List,
  ListItem,
  ListIcon,
  Icon,
} from "@chakra-ui/react";
import { MdCheckCircle, MdSettings } from 'react-icons/md'
import RightMenu from "components/UI/RightMenu";
import { useResponsive } from "hooks/useResponsive";

const AimsAndScope = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    const {IsMobile} = useResponsive();
    const IsRenderMobile = IsMobile();
    
    return (
    <>
      <HStack align="flex-start" 
        spacing="4"
      >
      
      <Box w="100%" textAlign="justify">
        <Box fontWeight='bold' pb="5">
          AIMS AND SCOPE
        </Box>
        <Box pb="3">
          <p>HO CHI MINH CITY UNIVERSITY JOURNAL of TRANSPORTATION SCIENCE and TECHNOLOGY journal that publishes research and application results in the field of transport and communications including:</p>
        </Box>
        <Box pb="3">
          <Grid templateColumns={IsRenderMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)"} gap="4" placeItems="right">
            <List spacing={3}>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='blue.500' />
                Transport Construction Engineering
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='blue.500' />
                Construction Economics
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='blue.500' />
                Mechanical Engineering
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='blue.500' />
                Transport Economics
              </ListItem>
                         
            </List>

            <List spacing={3}>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='blue.500' />
                Electrical - Communication Engineering
              </ListItem>   

              <ListItem>
                <ListIcon as={MdCheckCircle} color='blue.500' />
                Information Technology
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='blue.500' />
                Transport Safety and Environment
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='blue.500' />
                Maritime Sciences
              </ListItem>              
            </List>
          </Grid>
        </Box>
              
      </Box>
      {!IsRenderMobile && 
        <RightMenu/>}
    </HStack>

    {IsRenderMobile && 
      <RightMenu/>}
    </>
  );
};

export default AimsAndScope;
