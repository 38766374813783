import { Box, Button, HStack, VStack, Icon } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { axiosInstance } from "utils/axios";
import { HiOutlineMail } from "react-icons/hi";
import { timestampToDate } from "utils/time";
import { Link } from "react-router-dom";
import { useTranslate } from "hooks/useTranslate";
import { useResponsive } from "hooks/useResponsive";

export const ArticleDetail = () => {
  const {IsMobile} = useResponsive();
  const IsRenderMobile = IsMobile();
  const { t } = useTranslate();
  const [article, setArticle] = useState();
  const { id } = useParams();
  const [articles, setArticles] = useState([]);
  

  useEffect(() => {
    const getLatest10 = async () => {
      axiosInstance
        .get("/articles/latest")
        .then((res) => setArticles(res.data))
        .catch(console.error);
    };

    if (id) {
      axiosInstance
        .get(`/articles/${id}`)
        .then((res) => {
          setArticle(res.data);
        })
        .catch((err) => console.log(err));

      getLatest10();
    }

    window.scrollTo(0, 0);
  }, [id]);

  const hanldeDownload = () => {
    axiosInstance
      .get(`/articles/${id}/download`, {
        responseType: "blob",
      })
      .then(async (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        const fileType = article.file.filename.substr(article.file.filename.lastIndexOf("."));
        link.href = url;
        link.setAttribute("download", article.manuscriptId + fileType);
        document.body.appendChild(link);
        link.click();
      });
  };

  function LatestPost(props){
    return (
      <VStack
        align="stretch"
        //w="20em"
        minW="80"
        maxW={props.maxW}
        p="4"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="md"
        boxShadow=" 0 0 5px 0 rgb(128 128 128 / 10%)"
      >
        <Box textAlign="left"
              fontSize="lg"
              fontWeight="semibold"
              //color="blue.600"
        >
          LATEST ARTICLES
        </Box>
        
        <hr />
        <VStack align="stretch" flex="1" spacing="8">
          {articles.map((article) => (
            <Link to={`/articles/${article._id}`} key={article._id}>
              <VStack spacing="4" flex="1" align="stretch">
                <Box
                  fontSize="lg"
                  fontWeight="semibold"
                  color="blue.600"
                  cursor="pointer"
                  _hover={{
                    textDecor: "underline",
                  }}
                  className="two-line-text"
                >
                  {article.info?.title}
                </Box>
                <Box>
                  {/*timestampToDate(article.publishedDate)*/}
                  
                  {/* - {article.info?.authors
                    .map((author) => `${author.firstname} ${author.lastname}`)
                    .join("; ")} */}
                </Box>
              </VStack>
              <hr />
            </Link>
          ))}
        </VStack>
      </VStack>
    );
  }

  return article ? (
    <>
    <HStack align="flex-start" spacing="4" pb='2'>
      <VStack flex="1" align="stretch" spacing="8">
        <VStack align="stretch" spacing="4">
          {/*<Box color="blue.600" >
            Published: {timestampToDate(article.publishedDate)}
  </Box>*/}
          <Box fontSize="2xl" fontWeight="semibold" textAlign="justify">
            {article.info?.title}
          </Box>
          <HStack color="blue.600" spacing="8">
            {article.info?.authors.map((author) => (
              <a href={`mailto:${author.email}`}>
                <Box textDecor="underline" pos="relative">
                  {author.lastname} {author.firstname}
                  {author.id && (
                    <Icon
                      pos="absolute"
                      right="-4"
                      top="-1"
                      as={HiOutlineMail}
                    />
                  )}
                </Box>
              </a>
            ))}
          </HStack>
          <HStack color="blue.600" spacing="4">
            <Box>{article.volume?.name},</Box>
            <Box>{article.issue?.name},</Box>
            {/* <Box>Manuscript ID: {article.publicationCode},</Box> */}
            <Box>
              Pages: {article.pageNumberStart} - {article.pageNumberEnd},
            </Box>
            
            {!IsRenderMobile && article.doiLink && (
            <Box textDecor="underline" wordBreak={'break-word'}
            >
              DOI: {' '}
              <a href={article.doiLink} target={'_blank'}>
                {article.doiLink}
              </a>
            </Box>
            )}
          </HStack>

          {IsRenderMobile && article.doiLink && (
            <Box color="blue.600" textDecor="underline" wordBreak={'break-word'}
            >
              DOI: {' '}
              <a href={article.doiLink} target={'_blank'}>
                {article.doiLink}
              </a>
            </Box>
            )}

          <HStack color="blue.600" spacing="10">
            <Box>Downloads: {article.totalDownload}</Box>
            <Box textAlign="right">
              <Button colorScheme="blue" onClick={hanldeDownload} bgColor={'#3365a4'}>
                Download File
              </Button>
            </Box>
          </HStack>
        </VStack>

        <VStack align="stretch" spacing="4">
          <Box>
            <Box fontSize="xl" fontWeight="semibold">
              Abstract
            </Box>
            <hr />
          </Box>
          <Box textAlign="justify">{article.info?.summary}</Box>
        </VStack>

        <VStack align="stretch" spacing="4">
          <Box>
            <Box fontSize="xl" fontWeight="semibold">
              Keywords
            </Box>
            <hr />
          </Box>
          <Box textAlign="justify">{article.info?.keywords.filter(Boolean).join("; ")}</Box>
        </VStack>

        <VStack align="stretch" spacing="4">
          <Box>
            <Box fontSize="xl" fontWeight="semibold">
              Funding
            </Box>
            <hr />
          </Box>

          <Box>{article.info?.prizeDetail || "N/A"}</Box>
        </VStack>

        <VStack align="stretch" spacing="4">
          <Box>
            <Box fontSize="xl" fontWeight="semibold">
              Author information
            </Box>
            <hr />
          </Box>

          <VStack align="stretch">
            {article.info?.authors.map((author, idx) => (
              <Box>
                {author.id ? (
                  <a href={`mailto:${author.email}`}>
                    <Box d="inline" pos="relative" fontWeight="semibold">
                      {author.lastname} {author.firstname}
                      {author.id && (
                        <Icon
                          pos="absolute"
                          right="-4"
                          top="-1"
                          as={HiOutlineMail}
                        />
                      )}
                    </Box>
                  </a>
                ) : (
                  <Box fontWeight="semibold">
                    {author.lastname} {author.firstname}
                  </Box>
                )}

                <Box>{author.email}</Box>
                <Box>{author.school}{author.faculty &&(" - " + author.faculty)}</Box>
              </Box>
            ))}
          </VStack>
        </VStack>
      </VStack>

      {!IsRenderMobile && 
        <LatestPost
        maxW='30%' />}
    </HStack>

    {IsRenderMobile && 
      <LatestPost/>}
    </>
  ) : (
    <Box>Loading</Box>
  );
};
