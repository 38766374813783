import {
  Box,
  Input,
  Checkbox,
  CheckboxGroup,
  HStack,
  Select,
  VStack,
} from "@chakra-ui/react";
import { GlobalContext } from "context/GlobalContext";
import React, { useContext, useEffect } from "react";

export const FirstStep = ({
  articleType,
  setArticleType,
  researchTopics,
  setResearchTopics,
}) => {
  const { majors } = useContext(GlobalContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <HStack pb="4">
        <Box>
          Select article type <span className="required-field">(*)</span>
        </Box>
        <Box>
          <Select minW='200px' value="">
            <option value="">Manuscript</option>
          </Select>
        </Box>
      </HStack>

      <HStack pb='4'>
        <Box>
          Select research area <span className="required-field">(*)</span>
        </Box>
        <Box>
          {/* <Input
            minW='500px'
            value={articleType}
            placeholder="Ex: Electrical, Mechanical, ..."
            onChange={(e) => setArticleType(e.target.value)}
          /> */}

          <Select
            value={articleType}
            onChange={(e) => setArticleType(e.target.value)}
          >
            <option value={'Transport Construction Engineering'}>Transport Construction Engineering</option>
            <option value={'Construction Economics'}>Construction Economics</option>
            <option value={'Mechanical Engineering'}>Mechanical Engineering</option>
            <option value={'Transport Economics'}>Transport Economics</option>
            <option value={'Electrical - Communication Engineering'}>Electrical - Communication Engineering</option>
            <option value={'Information Technology'}>Information Technology</option>
            <option value={'Transport Safety and Environment'}>Transport Safety and Environment</option>
            <option value={'Maritime Sciences'}>Maritime Sciences</option>
          </Select>
        </Box>
      </HStack>

      <HStack>
        <Box>
          Enter research topic <span className="required-field">(*)</span>
        </Box>
        <Box>
          <Input
            minW='500px'
            value={researchTopics}
            placeholder="Ex: 5G technologies, Wireless communications, ..."
            onChange={(e) => setResearchTopics(e.target.value)}
          />
          
        </Box>
        
      </HStack>      
    </Box>
  );
};
