import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  VStack,
} from "@chakra-ui/react";
import { countries } from "assets/countries-states.json";
import { GlobalContext } from "context/GlobalContext";
import { useFormHook } from "hooks/useFormHook";
import { useTranslate } from "hooks/useTranslate";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { axiosInstance } from "utils/axios";
import * as yup from "yup";

const registerValidationSchema = yup.object().shape({
  email: yup.string().email("email_invalid").required(),
  password: yup.string().min(6).required(),
  title: yup.string().required(),
  firstname: yup.string().max(50).required(),
  lastname: yup.string().max(50).required(),
  phone: yup.string().required(),
  // postcode: yup.string().min(3).max(9).required(),
  country: yup.string().required(),
  city_provine: yup.string().required(),
  // address: yup.string().required(),
  university: yup.string().required(),
  // major: yup.string().required("department is a required field"),
});

export const Register = () => {
  const { t } = useTranslate();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useFormHook(registerValidationSchema);
  const history = useHistory();

  const { majors } = useContext(GlobalContext);

  const [countryCode, setCountryCode] = useState("VN");
  const [countryStates, setCountryStates] = useState([]);
  const [countryStateSelected, setCountryStateSelected] = useState();

  const [majorSelected, setMajorSelected] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    !!majors?.length && setMajorSelected(majors[0]._id);
  }, [majors]);

  useEffect(() => {
    if (countryCode) {
      const currentCountry = countries.find(
        (country) => country.code === countryCode
      );
      if (currentCountry) setCountryStates(currentCountry.states ?? []);
    }
  }, [countryCode]);

  useEffect(() => {
    if (countryStates) {
      const defaultState = countryStates[0];
      if (defaultState) setCountryStateSelected(defaultState.code);
    }
  }, [countryStates]);

  function onSubmit(values) {
    axiosInstance
      .post("/auth/register", values)
      .then((_) => {
        alert("Register success");
        history.push("/auth/login");
      })
      .catch((err) => console.error(err));
  }

  return (
    <VStack align="center">
      <Box w="48em">
        <form>
          <Grid templateColumns="repeat(2, 1fr)" gap="4">
            {/* <FormControl
              isInvalid={!!errors?.name?.message}
              errortext={errors?.name?.message}
              isRequired
            >
              <FormLabel>{t("username")}</FormLabel>
              <Input
                name="username"
                placeholder="Username"
                {...register("username")}
              />
              <FormErrorMessage>{errors?.username?.message}</FormErrorMessage>
            </FormControl> */}

            <FormControl
              isInvalid={!!errors?.email?.message}
              errortext={errors?.email?.message}
              isRequired
            >
              <FormLabel>{t("email")}</FormLabel>
              <Input
                name="email"
                placeholder=''
                {...register("email")}
              />
              <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={!!errors?.password?.message}
              errortext={errors?.password?.message}
              isRequired
            >
              <FormLabel>{t("password")}</FormLabel>
              <Input
                type="password"
                placeholder=''
                name="password"
                {...register("password")}
              />
              <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
            </FormControl>

            {/* <FormControl
              isInvalid={!!errors?.password?.message}
              errortext={errors?.password?.message}
              isRequired
            >
              <FormLabel>{t("password_confirm")}</FormLabel>
              <Input
                type="password"
                placeholder="Password"
                name="password"
                {...register("password")}
              />
              <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
            </FormControl> */}
            <FormControl
              isInvalid={!!errors?.title?.message}
              errortext={errors?.title?.message}
              isRequired
            >
              <FormLabel>Title</FormLabel>
              <Select
                name="title"
                {...register("title")}
                // onChange={(e) => setMajorSelected(e.target.value)}
                defaultValue=""
              >
                <option value="">Choose your title</option>
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
                <option value="Ms">Ms</option>
                <option value="Dr">Dr</option>
                <option value="Prof">Prof</option>
                <option value="Assoc. Prof">Assoc. Prof</option>
                <option value="MSc">MSc</option>
                <option value="BA">BA</option>
                <option value="Student">Student</option>
              </Select>
              <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
            </FormControl>
            <FormControl></FormControl>

            <FormControl
              isInvalid={!!errors?.firstname?.message}
              errortext={errors?.firstname?.message}
              isRequired
            >
              <FormLabel>{t("firstname")}</FormLabel>
              <Input
                name="firstname"
                placeholder=''
                {...register("firstname")}
              />
              <FormErrorMessage>{errors?.firstname?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={!!errors?.lastname?.message}
              errortext={errors?.lastname?.message}
              isRequired
            >
              <FormLabel>{t("lastname")}</FormLabel>
              <Input
                name="lastname"
                placeholder=''
                {...register("lastname")}
              />
              <FormErrorMessage>{errors?.lastname?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={!!errors?.university?.message}
              errortext={errors?.university?.message}
              isRequired
            >
              <FormLabel>{t("university")}</FormLabel>
              <Input
                name="university"
                placeholder=''
                {...register("university")}
              />
              <FormErrorMessage>
                {errors?.university?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={!!errors?.major?.message}
              errortext={errors?.major?.message}
              // isRequired
            >
              <FormLabel>{t("major")}</FormLabel>
              <Input
                name="major"
                placeholder=''
                {...register("major")}
              />
              <FormErrorMessage>{errors?.major?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={!!errors?.country?.message}
              errortext={errors?.country?.message}
              isRequired
            >
              <FormLabel>{t("country")}</FormLabel>
              <Select
                value={countryCode}
                // name="country"
                {...register("country")}
                onChange={(e) => setCountryCode(e.target.value)}
              >
                {countries.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors?.country?.message}</FormErrorMessage>
            </FormControl>

            {countryStates?.length > 0 && countryStateSelected && (
              <FormControl
                isInvalid={!!errors?.city_provine?.message}
                errortext={errors?.city_provine?.message}
                isRequired
              >
                <FormLabel>{t("city_provine")}</FormLabel>
                <Select
                  value={countryStateSelected}
                  {...register("city_provine")}
                  onChange={(e) => setCountryStateSelected(e.target.value)}
                >
                  {countryStates.map((state) => (
                    <option key={state.code} value={state.code}>
                      {state.name}
                    </option>
                  ))}
                </Select>

                <FormErrorMessage>
                  {errors?.city_provine?.message}
                </FormErrorMessage>
              </FormControl>
            )}

            <FormControl
              isInvalid={!!errors?.address?.message}
              errortext={errors?.address?.message}
              // isRequired
            >
              <FormLabel>{t("address")}</FormLabel>
              <Input
                name="address"
                placeholder=''
                {...register("address")}
              />
              <FormErrorMessage>{errors?.address?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={!!errors?.phone?.message}
              errortext={errors?.phone?.message}
              isRequired
            >
              <FormLabel>{t("phone")}</FormLabel>
              <Input
                name="phone"
                placeholder='0905 456 789'
                {...register("phone")}
              />
              <FormErrorMessage>{errors?.phone?.message}</FormErrorMessage>
            </FormControl>

            {/* <GridItem colSpan="1">
              
            </GridItem> */}
            

            

            <GridItem colSpan="2">
              <Box textAlign="center">
                <Button
                  onClick={handleSubmit(onSubmit)}
                  type="submit"
                  mx="4"
                  w="40"
                  colorScheme="blue"
                  variant="solid"
                  isLoading={isSubmitting}
                  disabled={!!errors?.email || !!errors?.password}
                >
                  {t("register")}
                </Button>
              </Box>
            </GridItem>
          </Grid>
        </form>
      </Box>
    </VStack>
  );
};
