import React, { useEffect } from "react";
import {
  Box,
  Button,
  HStack,
  Table,
  Tbody,
  Td,
  Tr,
  VStack,
  Link,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";
import RightMenu from "components/UI/RightMenu";
import { useTranslate } from "hooks/useTranslate";
import { useResponsive } from "hooks/useResponsive";

const ContactJournal = () => {
  const { t } = useTranslate();
  const {IsMobile} = useResponsive();
  const IsRenderMobile = IsMobile();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <HStack align="flex-start" spacing="4" pb='2'>
      <Box w="100%">
        <Box fontWeight='bold' pb="5">
          CONTACT JOURNAL
        </Box>
        <Box pb="3">
          <strong>
            {t("footer_department")}
            <br/>{t("footer_university")}
          </strong>
           
        </Box>
        
        <Box pb="3">
          <UnorderedList>
            <ListItem>{t("footer_address")}</ListItem>
            <ListItem>Hotline: 028 3803 5341</ListItem>
            <ListItem>Email: <Link color='blue' target='_blank' href="mailto:tapchikhcngtvt@ut.edu.vn">tapchikhcngtvt@ut.edu.vn</Link></ListItem>
          </UnorderedList>
        </Box>
              
      </Box>

      
      {!IsRenderMobile && 
        <RightMenu/>}
    </HStack>
  
    {IsRenderMobile && 
      <RightMenu/>}
    </>
  );
};

export default ContactJournal;
