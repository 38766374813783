import { Button } from "@chakra-ui/button";
import { Input } from "@chakra-ui/input";
import { HStack, Text, VStack } from "@chakra-ui/layout";
import React, { useState, useEffect } from "react";
import { axiosInstance } from "utils/axios";
import { useHistory } from "react-router-dom";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmitForgotPassword = () => {
    if (!email?.trim()) {
      return alert("Enter your email");
    }
    setSubmitting(true);
    var sEmail = String(email).toLowerCase();
    axiosInstance
      .post("/auth/forgot-password", { email: sEmail })
      .then((res) => {
        alert("New password sent to your email");
        setSubmitting(false);
        history.push("/auth/login");
      })
      .catch((err) => {
        setSubmitting(false);
        console.error(err);
        alert("Invalid email or something wrong! Please try again ...");
        // alert("Cannot")
      });
  };

  return (
    <HStack justify="center">
      <VStack w="96" spacing="4">
        <Text as="b" fontSize="xl">
          Forgot Password
        </Text>
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
        />
        <Button onClick={handleSubmitForgotPassword} colorScheme="blue">
          Submit
        </Button>
      </VStack>
    </HStack>
  );
}
