import {
  Box,
  Button,
  HStack,
  Table,
  Tbody,
  Td,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useEffect} from "react";
import { GlobalContext } from "context/GlobalContext";
import RightMenu from "components/UI/RightMenu";
import { Link } from "react-router-dom";
import { useTranslate } from "hooks/useTranslate";
import { useResponsive } from "hooks/useResponsive";

export const VolumesAndIssues = () => {
  const {IsMobile} = useResponsive();
  const IsRenderMobile = IsMobile();
  const { volumes } = useContext(GlobalContext);
  console.log(volumes);
  const { t } = useTranslate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <HStack align="flex-start" spacing="4" pb='2'>
      <VStack w="100%" align="stretch" flex="1" spacing="8">
        <Box fontSize="2xl" fontWeight="semibold">
          {t("volume_and_issues")}
        </Box>

        {volumes
          .sort((a, b) => b.createdAt - a.createdAt)
          .map((volume, idx) => (
            <Box key={idx}>
              <HStack justify="space-between">
                <Box fontSize="xl" fontWeight="semibold">
                  {volume.name}
                </Box>
                <Box>{volume.desc}</Box>
              </HStack>
              <hr />

              <Table variant="simple" color="blue.600">
                <Tbody>
                  {volume.issues
                    .sort((a, b) => b.createdAt - a.createdAt)
                    .map((issue) => (
                      <Tr>
                        <Td>
                          <Link to={`/volumes-and-issues/${issue._id}`}>
                            {issue.name}
                            {issue.desc && (
                              <Box color="gray.600" mt="2">
                                {issue.desc}
                              </Box>
                            )}
                          </Link>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </Box>
          ))}
      </VStack>

      {!IsRenderMobile && 
        <RightMenu/>}
    </HStack>
    {IsRenderMobile && 
      <RightMenu/>}
    </>
  );
};
