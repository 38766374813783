import {
  Box,
  Button,
  HStack,
  Image,
  Input,
  Icon,
  VStack,
  Grid,
} from "@chakra-ui/react";
import { HiOutlineMail, HiPhone, HiDesktopComputer, HiShare } from "react-icons/hi";
import { LANGUAGES_SUPPORTED } from "language/index";
import { GlobalContext } from "context/GlobalContext";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslate } from "hooks/useTranslate";
import { SearchIcon, TriangleDownIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router";
import GlobalVar from '../../utils/config'
import { BiHome, BiBook, BiGroup, BiLogIn } from "react-icons/bi";
import { RiComputerLine, RiMapPinLine } from "react-icons/ri";
import { useResponsive } from "hooks/useResponsive";

export const Layout = ({ children }) => {
  const { isAuthenticated, user, language, setLanguage, getLists, logout } =
    useContext(GlobalContext);
  const { t } = useTranslate();
  const history = useHistory();
  const {IsMobile} = useResponsive();
  const [query, setQuery] = useState("");
  const IsRenderMobile = IsMobile();

  const handleSearch = () => {
    if (query.trim()) {
      history.push(`/?q=${query}`);
    }
    else{
      history.push(`/`);
    }
  };

  useEffect(() => {
    getLists();
  }, []);

  const hanldeChangeLanguage = () => {
    const restLanguage = Object.values(LANGUAGES_SUPPORTED).find(
      (lang) => lang !== language
    );
    setLanguage(restLanguage);
  };

  return (
    <Box minH="100vh" pos="relative" pb="10px">
      {/* footer */}
      <Box
        h="8"
        pos="absolute"
        left="0"
        right="0"
        bottom="0"
        textAlign="center"
        py="1"
      >
        <Box>
          <br/>
        </Box>
        <Box bg="#3365a4" color="#fcfcfc" align='left' px='20px' py='20px'>
          <hr/>
          <HStack>
            <Grid templateColumns={IsRenderMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)"} 
              // gap="2" 
              placeItems="right">
              <Box alignSelf={'start'} pt='10px' 
                // fontSize={'18px'}
                >
                <strong><h2>{t("footer_title")} - {t("footer_university")}</h2></strong><br/>
                <a target='_blank' href="https://goo.gl/maps/TN5XpB19epi5occv8"><Icon as={RiMapPinLine}/> {t("footer_address")}</a><br/>
                <Icon as={HiPhone}/> Hotline: <a target='_blank' href="tel:028 3803 5341">028 3803 5341</a><br/>
                <Icon as={HiOutlineMail}/> Email: <a target='_blank' href="mailto:tapchikhcngtvt@ut.edu.vn">tapchikhcngtvt@ut.edu.vn</a><br/>
                <Icon as={HiDesktopComputer}/> Website: <a target='_blank' href={GlobalVar.FRONTEND_URI}>{GlobalVar.FRONTEND_URI}</a><br/>
                <Icon as={HiShare}/> Facebook: <a target='_blank' href="https://www.facebook.com/tapchikhcngtvt">https://www.facebook.com/tapchikhcngtvt</a><br/>
                <br/>

              </Box>
              <Box p='10px'>
                <iframe 
                  textAlign = 'center'
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.088714551496!2d106.7146120148009!3d10.804517192302438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3175293dceb22197%3A0x755bb0f39a48d4a6!2zVHLGsOG7nW5nIMSQ4bqhaSBI4buNYyBHaWFvIFRow7RuZyBW4bqtbiBU4bqjaSBUaMOgbmggUGjhu5EgSOG7kyBDaMOtIE1pbmg!5e0!3m2!1sen!2s!4v1645107147654!5m2!1sen!2s" 
                  width="100%" 
                  height="100%"
                  allowfullscreen="" 
                  loading="lazy">
                </iframe>
              </Box>
            </Grid>
          </HStack>
          <br/>
          <hr/>
          <span><i>Copyright &copy;{new Date().getFullYear()} Ho Chi Minh City University of Transport  - <a target="_blank" href="https://ut.edu.vn">(UTH)</a></i></span>
        </Box>
      </Box>

      {/* Header */}
      <HStack
        h={20}
        px={4}
        py={4}
        align="center"
        borderBottom="2px solid"
        borderColor="gray.400"
        bg="#3365a4"
        zIndex="999"
      >
        <Link to="/">
          <HStack  flex="1" justify="flex-end">
          <Box h={16}>
            <Image
              h="100%"
              src= {GlobalVar.FRONTEND_URI + '/Logo.png'}
              alt="logo"
            />
          </Box>
          <Box 
            hidden = {IsRenderMobile}
            color="white" 
            fontSize="md" fontWeight="semibold" fontFamily={"'Roboto Condensed', sans-serif;"}>
            {t("TenTapChi1")}
            <br/>
            {t("TenTapChi2")}
        </Box>
          </HStack>
          
        </Link>

        <HStack flex="1" justify="flex-end">
          <HStack flex="1" justify="center">
            <HStack
              flex="1"
              maxW={{
                base: "25em",
                lg: "40em",
              }}
            >
              <Box flex="1">
                <Input
                  color="#3365a4"
                  bg="white"
                  placeholder="Search by author, title, abstract, keywords ..."
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </Box>
              <Button colorScheme="teal" onClick={handleSearch}>
                <Icon
                  //pos="absolute"
                  //right="-4"
                  //top="-1"
                  as={SearchIcon}
                />
            
              </Button>
            </HStack>
          </HStack>

          <HStack>            
            {/* <Button
              size="sm"
              fontWeight="bold"
              colorScheme="blue"
              onClick={hanldeChangeLanguage}
              hidden = {true}
            >
              {language}
            </Button> */}

            <Link to="/auth/login" hidden = {isAuthenticated}>
              <Button colorScheme="teal" textDecor="underline" >
                {!IsRenderMobile && t("login")}
                <Icon as={BiLogIn}  />
              </Button>
            </Link>

            {isAuthenticated && (
              <Box pos="relative" role="group">
                <Button
                  rightIcon={
                    <TriangleDownIcon
                      w={3}
                      h={3}
                      transition="transform .3s ease"
                      _groupHover={{
                        transform: "rotate(180deg)",
                      }}
                    />
                  }
                  colorScheme="teal"
                >
                  {user.lastname} {user.firstname}
                </Button>
                <Box
                  d="none"
                  pos="absolute"
                  right="0"
                  top="calc(100%)"
                  minW="10em"
                  pt="2"
                  _groupHover={{
                    d: "block",
                  }}
                  zIndex="2"
                >
                  <VStack align="stretch" bg="gray.200" p="2">
                    <Link to="/dashboard">
                      <Box
                        p="1"
                        borderRadius="md"
                        cursor="pointer"
                        _hover={{
                          bg: "gray.400",
                        }}
                      >
                        Dashboard
                      </Box>
                    </Link>

                    <Link to="/account">
                      <Box
                        p="1"
                        borderRadius="md"
                        cursor="pointer"
                        _hover={{
                          bg: "gray.400",
                        }}
                      >
                        Update my information
                      </Box>
                    </Link>

                    <Link to="/account/change-password">
                      <Box
                        p="1"
                        borderRadius="md"
                        cursor="pointer"
                        _hover={{
                          bg: "gray.400",
                        }}
                      >
                        Change password
                      </Box>
                    </Link>
                    <Button
                      colorScheme="red"
                      size="sm"
                      onClick={() => logout()}
                    >
                      Logout
                    </Button>
                  </VStack>
                </Box>
              </Box>
            )}
          </HStack>
        </HStack>
      </HStack>

      {/* Menu Top  */}      
      <HStack h="40px"
        px={4}
        py={4}
        align="center"
        spacing={IsRenderMobile ? "2" : "10"}
        color="blue.600">
              
              <HStack>
                <Icon as={BiHome} w="6" h="6" />
                <Link
                  to={{
                    pathname: "/",
                  }}
                >
                  <Box textDecor="underline" cursor="pointer">
                    Home
                  </Box>
                </Link>
              </HStack>

              <HStack>
                <Icon as={BiGroup} w="6" h="6" />
                <Link
                  to={{
                    pathname: "/editorial-board/",
                  }}
                >
                  <Box textDecor="underline" cursor="pointer">
                    {t("editorial_board")}
                  </Box>
                </Link>
              </HStack>
              <HStack>
                <Icon as={BiBook} w="6" h="6" />

            <Link
              to={{
                pathname: "/aims-and-scope/",
              }}
              //target="_blank"
            >
              <Box textDecor="underline" cursor="pointer">
                {t("aims_and_scope")}
              </Box>
            </Link>
          </HStack>
          <HStack hidden={true}>
            <Icon as={RiComputerLine} w="6" h="6" />
            <Link
              to={{
                pathname: "/journal-updates/",
              }}
              //target="_blank"
            >
              <Box textDecor="underline" cursor="pointer">
                {t("journal_updates")}
              </Box>
            </Link>
          </HStack>
      </HStack>
      
      <hr />

      <Box p="4" className="main-contanier">
        {children}
      </Box>
    </Box>
  );
};
