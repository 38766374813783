import {
  Box,
  Checkbox,
  HStack,
  
  VStack,
  
  ListItem,
  
  OrderedList,
  
} from "@chakra-ui/react";
import React, {useEffect} from "react";

export const ThirdStep = ({
  isPosted,
  wherePosted,
  canShareManuscript,
  isAcceptTerm,
  onChange,
}) => {
  console.log(isPosted, canShareManuscript, isAcceptTerm);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <VStack spacing="4" align="flex-start">
      <Box>
        <Box pb={3}>Under the authority of authors of this article, I pledge the following content:
        <br/><i>Tôi được sự ủy quyền của nhóm tác giả trong bài báo này, xin cam kết những nội dung dưới đây:</i></Box>
        
        <OrderedList spacing={2} textAlign={'justify'}>
          <ListItem>I am the corresponding author of research work with above title.
            <br/><i>Tôi là tác giả liên hệ của công trình có tiêu đề trên.</i></ListItem>
          <ListItem>Finding in manuscript belongs to group of authors of this article themselves.
            <br/><i>Kết quả nghiên cứu nêu trong bản thảo là của chính nhóm tác giả có tên trong bài thực hiện.</i></ListItem>
          <ListItem>The content of article has never been submitted or published in any national or international journals.
            <br/><i>Nội dung trong bài chưa được gửi đăng hoặc công bố trên bất kỳ tạp chí quốc gia hay quốc tế khác.</i></ListItem>
          <ListItem>All the authors of this article have read the manuscript and agreed on the order of authors and agreed to publish this article on Journal of Science – Technology & Transport.
            <br/><i>Tất cả các tác giả có tên trong bài trên đều đã đọc bản thảo, đã thỏa thuận về thứ tự tác giả và đồng ý gửi đăng trên Tạp chí Khoa học công nghệ Giao thông vận tải.</i></ListItem>
          <ListItem>The research work has no conflict of benefits between authors of this article and other groups of authors as well as any employer/foundation in case of the research work made for hire.
            <br/><i>Công trình này không có bất kỳ sự xung đột về lợi ích nào giữa các tác giả trong bài và với tác giả khác.</i></ListItem>
          <ListItem>Research work follows the rules of morality in research.
            <br/><i>Nghiên cứu đã tuân thủ đúng các khía cạnh đạo đức trong nghiên cứu khoa học.</i></ListItem>
          <ListItem>Journal of Science – Technology & Transport has the full right to use this research work (after being permitted to be promulgated) and publish in different methods (paper and online).
            <br/><i>Tạp chí Khoa học công nghệ Giao thông vận tải có toàn quyền sử dụng công trình này (sau khi được chấp nhận công bố) để phát hành bằng các hình thức khác nhau (bản in giấy và điện tử).</i></ListItem>
        </OrderedList>
      </Box>

      <HStack align="flex-start" spacing="4">
        <Box pt="2">
          <Checkbox
            color='blue'
            border='2px solid'
            isChecked={isAcceptTerm}
            onChange={(e) => onChange("is_accept", !isAcceptTerm)}
          />
        </Box>
        <Box fontWeight='bold' color={'blue'}>
          {/* <span className="required-field">(*) </span> */}
          I read and understand the policy of publication of Journal. I am fully responsible for my above commitment as well as the content of this research work.
          <br/><i>Tôi đã đọc và hiểu rõ Thể lệ đăng bài của Tạp chí. Tôi xin hoàn toàn chịu trách nhiệm với những cam kết của mình ở trên cũng như nội dung của công trình công bố.</i>
        </Box>
      </HStack>
    </VStack>
  );
};
